import {
  AvailableLanguagesExtended,
  availableLanguages as getAvailableLanguages,
  intl,
  AvailableLanguage,
} from '@global-website/library';

import translations from '../../Assets/I18n/messages';

export const intlDe = intl(AvailableLanguagesExtended.DeDE, translations.de);

export const availableLanguages: AvailableLanguage[] =
  getAvailableLanguages(translations);
