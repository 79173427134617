import React from 'react';
import ReactPlayer from 'react-player';
import { Col, Row } from 'reactstrap';

import { MediaPosition, MediaSize } from '@global-website/library';

export interface TextVideoHorizontalProps {
  videoPosition: MediaPosition;
  mediaSize: MediaSize;
  url: string;
}

export const TextVideoHorizontal: React.FC<TextVideoHorizontalProps> = ({
  videoPosition,
  mediaSize,
  url,
}) => {
  let col = 10;
  let colClassName = 'offset-md-1';

  switch (mediaSize) {
    case MediaSize.Large:
      col = 12;
      colClassName = '';
      break;
    case MediaSize.Small:
      col = 8;
      colClassName = 'offset-md-2';
      break;
    case MediaSize.Default:
    default:
      break;
  }

  return (
    <Row>
      <Col md={col} className={colClassName}>
        <div className="player-wrapper">
          <ReactPlayer
            width="100%"
            height="100%"
            className="react-player"
            url={url}
          />
        </div>
      </Col>
    </Row>
  );
};
