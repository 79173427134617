import {
  AvailableLanguage,
  AvailableLanguagesExtended,
  LocalizeActionKeys,
  LocalizeActions,
} from '@global-website/library';

import { availableLanguages, intlDe } from '../Intl';
import { assignLanguage, getIntlObject } from './Utils';

const intlObject = getIntlObject(
  assignLanguage(
    (navigator.language === 'de'
      ? 'de-DE'
      : navigator.language) as AvailableLanguagesExtended,
  ),
  true,
);

const initialState = intlObject || availableLanguages[0];

export const localizeReducer = (
  state: AvailableLanguage = initialState,
  action: LocalizeActions,
) => {
  switch (action.type) {
    case LocalizeActionKeys.Select:
      return getIntlObject(action.payload) || initialState;
    case LocalizeActionKeys.Reset:
      return intlDe;
    default:
      return state;
  }
};
