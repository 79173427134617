import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Homepage, RequestStatus } from '@global-website/library';

import { usePages, useRoutes } from '../../Hooks';
import { CookieConsent } from '../CookieConsent';
import { ContactBox, Footer, FooterCopyright } from '../Footer';
import { NavbarComponent } from '../Navbar';
import { HomepageResolver, NotFound404, PageAdmin, PageLogin } from '../Pages';

const HomepageRoutes: React.FC<Homepage> = (props) => {
  const pages = usePages(props.id);

  const routes = useRoutes({
    isAdmin: false,
    pages: pages?.result,
  });

  return (
    <div className="page" id="page">
      {pages?.status === RequestStatus.Loaded && (
        <>
          <NavbarComponent pages={pages && pages.result} homePageData={props} />
          <div className="main-body" id="main_body">
            <main>
              <Switch>
                <Route
                  path="/"
                  exact={true}
                  render={() => (
                    <HomepageResolver homepage={props} isAdmin={false} />
                  )}
                />
                {routes.map((route, index) => (
                  <Route {...route} key={`main_route_${index}`} />
                ))}
                <Route path="/admin" exact={false} component={PageAdmin} />
                <Route path="/auth/login" exact={true} component={PageLogin} />
                {
                  <Route
                    render={() => {
                      return <NotFound404 />;
                    }}
                  />
                }
              </Switch>
            </main>
            <footer>
              <ContactBox contact={props.contact} />
              <Footer homepage={props} />
              <FooterCopyright {...props} />
            </footer>
            {props.consent && (
              <CookieConsent {...props.consent} host={props.url} />
            )}
          </div>
        </>
      )}
      {pages?.status === RequestStatus.Error && <NotFound404 />}
    </div>
  );
};

export default HomepageRoutes;
