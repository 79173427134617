import isPrivateIp from 'private-ip';
import React from 'react';

import { isDevelopment, RequestStatus } from '@global-website/library';

import { SetScrollPosition } from '../Library';
import { useHomepage } from './Hooks';
import { Loader, NotFound, NotFound404 } from './Partials';
import LoaderMain from './Partials/Loader/LoaderMain';
import HomepageRoutes from './Partials/Routes/HomepageRoutes';

export const App: React.FC = () => {
  const location = window.location;

  const hostname =
    isDevelopment &&
    (['localhost'].includes(location?.hostname) ||
      isPrivateIp(location?.hostname))
      ? 'globalct.com'
      : location.hostname;

  const { result: homepage, status } = useHomepage(hostname);

  switch (status) {
    case RequestStatus.Loaded:
      return (
        <>
          <Loader />
          <SetScrollPosition>
            {homepage ? <HomepageRoutes {...homepage} /> : <NotFound />}
          </SetScrollPosition>
        </>
      );
    case RequestStatus.Error:
      return <NotFound404 />;
    case RequestStatus.Initial:
    case RequestStatus.Updating:
    default:
      return <LoaderMain />;
  }
};

export default App;
