import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';

import {
  MediaSize,
  TextWithImage as TextWithImageProps,
} from '@global-website/library';

import { MarkdownRenderer } from '../../../../Library';
import { TextImage } from './TextImage';

export const TextWithImageRight: React.FC<
  TextWithImageProps & { forJobs?: boolean }
> = ({
  __component,
  content,
  forJobs,
  isFluid,
  media,
  mediaPosition,
  mediaSize,
  showCaption,
}) => {
  const className = __component.replace(/\./g, '-').toLowerCase();
  let colText = 7;
  let colImage = 5;

  switch (mediaSize) {
    case MediaSize.Large:
      colText = 5;
      colImage = 7;
      break;
    case MediaSize.Small:
      colText = 9;
      colImage = 3;
      break;
    case MediaSize.Default:
    default:
      break;
  }

  return (
    <div className={classNames(className, `${className}-bottom`)}>
      <div
        className={classNames({
          container: !forJobs && !isFluid,
          'container-fluid': !forJobs && isFluid,
        })}
      >
        <Row>
          <Col
            md={!forJobs ? 10 : 12}
            className={classNames({ 'offset-md-1': !forJobs })}
          >
            <Row>
              <Col md={colText}>
                <MarkdownRenderer content={content} />
              </Col>
              <Col md={colImage}>
                {media && (
                  <TextImage
                    mediaSize={mediaSize}
                    mediaPosition={mediaPosition}
                    showCaption={showCaption}
                    media={media}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
