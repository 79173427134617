import React from 'react';
import { Image } from '../../Image';
import { Page, TabItem as TabItemPageProps } from '@global-website/library';
import { MarkdownRenderer } from '../../../../Library';

export const TabItemPage: React.FC<TabItemPageProps<Page>> = (props) => {
  return (
    (props.media && (
      <>
        <div className="tab-slider-item">
          <Image className="tab-slider-img" {...props.media} />
          <div className="slider-content">
            <h1 className="item-title">{props.title}</h1>
            {(props.lead && <h3 className="item-lead">{props.lead}</h3>) ||
              (props.teaser && (
                <MarkdownRenderer
                  className="item-lead"
                  content={props.teaser}
                />
              ))}
          </div>
        </div>
      </>
    )) || (
      <>
        <div className="tab-slider-item">
          <div className="slider-content">
            <h1 className="item-title">{props.title}</h1>
            {(props.lead && <h3 className="item-lead">{props.lead}</h3>) ||
              (props.teaser && (
                <MarkdownRenderer
                  className="item-lead"
                  content={props.teaser}
                />
              ))}
          </div>
        </div>
      </>
    ) ||
    null
  );
};

export default TabItemPage;
