import { defineMessages } from 'react-intl'

const MESSAGES = defineMessages({
  save: {
    id: 'CookieConsent.save',
    defaultMessage: 'Save',
  },
  weAreUsingCookies: {
    id: 'CookieConsent.weAreUsingCookies',
    defaultMessage: 'We are using cookies.',
  },
  standardCookiesText: {
    id: 'CookieConsent.standardCookiesText',
    defaultMessage: 'Standard cookies for the sites to work.',
  },
  standardCookiesMandatoryText: {
    id: 'CookieConsent.standardCookiesMandatoryText',
    defaultMessage: 'Default cookies, mandatory to make this site work.',
  },
});

export default MESSAGES;
