import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, match as Match, RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import {
  Branch,
  BranchList,
  ReactReduxRequestDispatch,
  requestAction,
  RequestStatus,
  WithConfigProps,
} from '@global-website/library';

import {
  GlobalState,
  PublicRequestKeys,
  withConfig,
} from '../../../../Library';
import ContentResolver from '../ContentResolver';
import { JumbotronComponent } from '../Jumbotron';
import MESSAGES from './Messages';

export interface BranchDetailProps extends WithConfigProps {
  [PublicRequestKeys.Branch]: GlobalState[PublicRequestKeys.Branch];
  [PublicRequestKeys.Pages]: GlobalState[PublicRequestKeys.Pages];
  branchList: BranchList;
  dispatch: ReactReduxRequestDispatch;
  isAdmin: boolean;
  path: string;
  routeProps: RouteComponentProps;
}

export class BranchDetailBase extends React.Component<BranchDetailProps> {
  request() {
    const { config, dispatch, routeProps } = this.props;

    const pageId = this.getCurrentId();

    if (config) {
      const url = config.api.createApiUrl(config.api.config);
      const match = routeProps.match as Match<{ slug: string }>;
      url.pathname = 'branch/find-by-slug/' + match.params.slug;
      url.search = (pageId && '?page.id=' + pageId) || '';

      dispatch(
        requestAction.load(PublicRequestKeys.Branch, {
          url: url.href,
        }),
      );
    }
  }

  public componentDidMount() {
    this.request();
  }

  public componentDidUpdate(prevProps: BranchDetailProps) {
    const { routeProps } = this.props;

    if (
      routeProps.location.pathname !== prevProps.routeProps.location.pathname
    ) {
      this.request();
    }
  }

  public componentWillUnmount() {
    const { dispatch, branch } = this.props;

    if (branch.status === RequestStatus.Loaded) {
      dispatch(requestAction.clear(PublicRequestKeys.Branch));
    }
  }

  private get branch(): Branch | undefined {
    const { branch } = this.props;

    return branch.result ? branch.result : undefined;
  }

  private getCurrentId() {
    const { pages: data, path } = this.props;

    const pages = data && data.result;
    let currentPath = path.replaceAll('/', '');

    if (!pages || (pages && !pages.length)) {
      return;
    }

    const page = pages.find((item) => item.title === currentPath);

    return (page && page.id) || undefined;
  }

  render() {
    const { isAdmin, path } = this.props;

    return (
      <>
        {this.branch && <JumbotronComponent {...this.branch.header} />}
        <section className="section section-detail section-detail-branch">
          <div className="page-content-text-lead">
            <Container>
              <Row>
                <Col md={10} className="offset-md-1">
                  <p className="lead">{this.branch?.lead}</p>
                </Col>
              </Row>
            </Container>
          </div>
          {this.branch && (
            <ContentResolver
              contents={this.branch.content}
              path={path}
              isAdmin={isAdmin}
            />
          )}
        </section>

        {this.branch?.page && (
          <section className="section section-detail-footer">
            <Container fluid={true}>
              <Row>
                <Col>
                  <Link
                    to={encodeURI(`/${this.branch.page.slug}`)}
                    title={this.branch.page.name || this.branch.page.title}
                  >
                    <i className="icon icon-arrow-left" />
                    <span className="ml-3">
                      <FormattedMessage {...MESSAGES.backTo} />{' '}
                      {this.branch.page.name || this.branch.page.title}{' '}
                      <FormattedMessage {...MESSAGES.overview} />
                    </span>
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </>
    );
  }
}

export const BranchDetail = connect((state: GlobalState) => ({
  [PublicRequestKeys.Branch]: state[PublicRequestKeys.Branch],
  [PublicRequestKeys.Pages]: state[PublicRequestKeys.Pages],
}))(withConfig(BranchDetailBase));

export default BranchDetail;
