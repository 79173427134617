import { apiConfig, Download } from '@global-website/library/build';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { useConfig } from '../../../../Library';

import { Image } from '../../Image';
import { ShareButtons } from '../../Share';

import MESSAGES from './Messages';

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const DownloadItem: FunctionComponent<
  Download & { isLast: boolean }
> = ({ file, previewImage, title, lead, isLast }) => {
  const config = useConfig();
  const url = config.createApiUrl(apiConfig);
  url.pathname = file.url;

  const isPdf = file.url.endsWith('.pdf');

  return (
    <div
      className={classNames('download-item', {
        'mb-4': !isLast,
        'pb-4': !isLast,
        'border-bottom': !isLast,
      })}
    >
      <Row>
        <Col xs={4} sm={4}>
          {previewImage && <Image {...previewImage} />}
        </Col>
        <Col xs={8} sm={8}>
          {(title || lead) && (
            <>
              {title && <h4 className="h3 mb-0">{title}</h4>}
              {lead && <p>{lead}</p>}
            </>
          )}
          <div className="download-list-share">
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <a
                  className="btn btn-blue btn-ghost d-block d-md-inline-block"
                  href={url.href}
                  title={`Download ${file.name}`}
                  {...(isPdf
                    ? { rel: 'noreferrer', target: '_blank' }
                    : { rel: 'download' })}
                >
                  <i className="fa fa-download" />{' '}
                  <span className="d-none d-xl-inline">
                    <FormattedMessage {...MESSAGES.download} />{' '}
                  </span>
                  ({formatBytes(file.size)})
                </a>
              </Col>
              <Col>
                <ShareButtons
                  name={title}
                  source={url.href}
                  summary={lead}
                  url={url.href}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
