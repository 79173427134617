import { stringify } from 'qs';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import {
  DownloadList as DownloadListProps,
  DownloadSorting,
  QueryParameter,
  requestAction,
  RequestStatus,
} from '@global-website/library';

import { GlobalState, PublicRequestKeys, useConfig } from '../../../../Library';
import { DownloadListSelected } from './';

export const DownloadList: FunctionComponent<DownloadListProps> = ({
  itemCount,
  sorting,
  ...rest
}) => {
  const { result: downloads, status } = useSelector<
    GlobalState,
    GlobalState[PublicRequestKeys.Downloads]
  >((state) => state[PublicRequestKeys.Downloads]);

  const dispatch = useDispatch();

  const config = useConfig();

  const queryParameter: QueryParameter = {
    ...(itemCount ? { _limit: itemCount } : {}),
    _sort: `title:${sorting === DownloadSorting.Desc ? 'DESC' : 'ASC'}`,
  };

  const url = config.createApiUrl(config.api.config);
  url.pathname = `downloads`;
  url.search = stringify(queryParameter);
  console.log(url);

  useEffect(() => {
    if (status === RequestStatus.Initial) {
      dispatch(
        requestAction.load(PublicRequestKeys.Downloads, {
          method: 'GET',
          url: url.href,
        }),
      );
    }
  }, [dispatch, status, url.href]);

  useUnmount(() => {
    dispatch(requestAction.clear(PublicRequestKeys.Downloads));
  });

  if (!downloads) {
    return null;
  }

  return <DownloadListSelected {...rest} downloads={downloads} />;
};
