/* tslint:disable:quotemark */

/* tslint:disable:max-line-length */

// DO NOT EDIT OR CHANGE THIS FILE!!

import { AvailableTranslations } from '@global-website/library';

const translations: AvailableTranslations = {
  "defaults": {
    "Blog.backToBlockOverview": "Back to blog overview",
    "Blog.seeHow": "see how",
    "Blog.WrittenBy": "by",
    "Blog.WrittenOn": "Written on",
    "Branch.backTo": "Back to",
    "Branch.overview": "Overview",
    "Branch.readMore": "Read more",
    "Downloads.download": "Download",
    "Job.backTo": "Back to",
    "Job.overview": "Overview",
    "Job.applyNow": "Apply now",
    "Job.position": "Position",
    "Job.location": "Location",
    "Job.start": "Start",
    "Job.contactPerson": "Contact Person",
    "Job.eMail": "Email",
    "Job.phone": "Phone",
    "Job.writeTo": "Write to",
    "Job.fromNowOn": "from now on",
    "Job.readMore": "Read more",
    "Recruitment.backTo": "Back to",
    "Recruitment.overview": "Overview",
    "Recruitment.applyNow": "Apply now",
    "Recruitment.position": "Position",
    "Recruitment.location": "Location",
    "Recruitment.start": "Start",
    "Recruitment.contactPerson": "Contact Person",
    "Recruitment.eMail": "Email",
    "Recruitment.phone": "Phone",
    "Recruitment.writeTo": "Write to",
    "Recruitment.fromNowOn": "from now on",
    "Recruitment.readMore": "Read more",
    "Services.backTo": "Back to",
    "Services.overview": "Overview",
    "ServicesSlider.toServiceDetail": "View Services",
    "Tab.goTo": "go To",
    "CookieConsent.save": "Save",
    "CookieConsent.weAreUsingCookies": "We are using cookies.",
    "CookieConsent.standardCookiesText": "Standard cookies for the sites to work.",
    "CookieConsent.standardCookiesMandatoryText": "Default cookies, mandatory to make this site work.",
    "Footer.language": "Language",
    "Loader.save": "Loading...",
    "NotFound.backHome": "Back to Homepage",
    "NotFound.teaser": "Sorry ... something went wrong. The page you were looking for doesn't exist.",
    "NotFound.Ooops": "Ooops",
    "PageAdmin.login": "login",
    "PageAdmin.please": "Please",
    "Share.shareOn": "Share on",
    "Share.share": "Share"
  },
  "en": {
    "NotFound.backHome": "Back to Homepage",
    "NotFound.teaser": "Sorry ... something went wrong. The page you were looking for doesn't exist.",
    "NotFound.Ooops": "Ooops",
    "ServicesSlider.toServiceDetail": "View Services",
    "Blog.backToBlockOverview": "Back to blog overview",
    "Blog.seeHow": "see how",
    "Branch.backTo": "Back to",
    "Branch.overview": "Overview",
    "Branch.readMore": "Read more",
    "Job.backTo": "Back to",
    "Job.overview": "Overview",
    "Job.applyNow": "Apply now",
    "Job.position": "Position",
    "Job.location": "Location",
    "Job.start": "Start",
    "Job.contactPerson": "Contact Person",
    "Job.eMail": "Email",
    "Job.phone": "Phone",
    "Job.writeTo": "Write to",
    "Job.fromNowOn": "from now on",
    "Job.readMore": "Read more",
    "Recruitment.backTo": "Back to",
    "Recruitment.overview": "Overview",
    "Recruitment.applyNow": "Apply now",
    "Recruitment.position": "Position",
    "Recruitment.location": "Location",
    "Recruitment.start": "Start",
    "Recruitment.contactPerson": "Contact Person",
    "Recruitment.eMail": "Email",
    "Recruitment.phone": "Phone",
    "Recruitment.writeTo": "Write to",
    "Recruitment.fromNowOn": "from now on",
    "Recruitment.readMore": "Read more",
    "Services.backTo": "Back to",
    "Services.overview": "Overview",
    "Tab.goTo": "go To",
    "CookieConsent.save": "Save",
    "CookieConsent.weAreUsingCookies": "We are using cookies.",
    "CookieConsent.standardCookiesText": "Standard cookies for the sites to work.",
    "CookieConsent.standardCookiesMandatoryText": "Default cookies, mandatory to make this site work.",
    "Loader.save": "Loading...",
    "PageAdmin.login": "login",
    "PageAdmin.please": "Please",
    "Footer.language": "Language",
    "Blog.WrittenBy": "by",
    "Blog.WrittenOn": "Written on",
    "Downloads.download": "Download",
    "Share.shareOn": "Share on",
    "Share.share": "Share"
  },
  "de": {
    "NotFound.backHome": "Zur Homepage",
    "NotFound.teaser": "Entschuldige ... irgendwas ist schief gelaufen. Die Seite die du aufgerufen hast existiert nicht.",
    "NotFound.Ooops": "Uuups",
    "ServicesSlider.toServiceDetail": "Zu unseren Services",
    "Blog.backToBlockOverview": "Zurück zur Blogübersicht",
    "Blog.seeHow": "Jetzt ansehen",
    "Branch.backTo": "Zurück zu",
    "Branch.overview": "Übersicht",
    "Branch.readMore": "Mehr erfahren",
    "Job.backTo": "Zurück zu",
    "Job.overview": "Übersicht",
    "Job.applyNow": "Jetzt bewerben",
    "Job.position": "Position",
    "Job.location": "Standort",
    "Job.start": "Start",
    "Job.contactPerson": "Ansprechpartner",
    "Job.eMail": "E-Mail",
    "Job.phone": "Telefon",
    "Job.writeTo": "Schreibe an",
    "Job.fromNowOn": "von jetzt an",
    "Job.readMore": "Mehr erfahren",
    "Recruitment.backTo": "Zurück zu",
    "Recruitment.overview": "Übersicht",
    "Recruitment.applyNow": "Jetzt bewerben",
    "Recruitment.position": "Position",
    "Recruitment.location": "Standort",
    "Recruitment.start": "Start",
    "Recruitment.contactPerson": "Ansprechpartner",
    "Recruitment.eMail": "E-Mail",
    "Recruitment.phone": "Telefon",
    "Recruitment.writeTo": "Schreibe an",
    "Recruitment.fromNowOn": "Ab sofort",
    "Recruitment.readMore": "Mehr erfahren",
    "Services.backTo": "Zurück zu",
    "Services.overview": "Übersicht",
    "Tab.goTo": "Mehr",
    "CookieConsent.save": "Speichern",
    "CookieConsent.weAreUsingCookies": "Wir benutzen Cookies.",
    "CookieConsent.standardCookiesText": "Standard Cookies",
    "CookieConsent.standardCookiesMandatoryText": "Default Cookies, Notwendig damit diese Seite funktioniert.",
    "Loader.save": "Lädt...",
    "PageAdmin.login": "login",
    "PageAdmin.please": "Please",
    "Footer.language": "Sprache",
    "Blog.WrittenBy": "von",
    "Blog.WrittenOn": "Geschrieben am",
    "Downloads.download": "Download",
    "Share.shareOn": "Teile auf",
    "Share.share": "Teile"
  },
  "sq": {
    "Blog.backToBlockOverview": "Kthehu në përmbledhjen e blogut",
    "Blog.seeHow": "shiko si",
    "Branch.backTo": "Prapa tek",
    "Branch.overview": "Përmbledhje",
    "Branch.readMore": "Lexo më shumë",
    "Job.backTo": "Prapa tek",
    "Job.overview": "Përmbledhje",
    "Job.applyNow": "Apliko tani",
    "Job.position": "Pozicioni",
    "Job.location": "Vendodhja",
    "Job.start": "Fillimi",
    "Job.contactPerson": "Personi i Kontaktit",
    "Job.eMail": "Email",
    "Job.phone": "Telefon",
    "Job.writeTo": "Shkruaj tek",
    "Job.fromNowOn": "që nga tani",
    "Job.readMore": "Lexo më shumë",
    "Recruitment.backTo": "Prapa tek",
    "Recruitment.overview": "Përmbledhje",
    "Recruitment.applyNow": "Apliko tani",
    "Recruitment.position": "Pozicioni",
    "Recruitment.location": "Vendodhja",
    "Recruitment.start": "Fillimi",
    "Recruitment.contactPerson": "Personi i Kontaktit",
    "Recruitment.eMail": "Email",
    "Recruitment.phone": "Telefon",
    "Recruitment.writeTo": "Shkruaj tek",
    "Recruitment.fromNowOn": "që nga tani",
    "Recruitment.readMore": "Lexo më shumë",
    "Services.backTo": "Prapa tek",
    "Services.overview": "Përmbledhje",
    "ServicesSlider.toServiceDetail": "Shiko Shërbimet",
    "Tab.goTo": "shko Tek",
    "CookieConsent.save": "Ruaj",
    "CookieConsent.weAreUsingCookies": "Ne po përdorim cookies.",
    "CookieConsent.standardCookiesText": "Cookies Standard për të funkionuar faqja.",
    "CookieConsent.standardCookiesMandatoryText": "Cookies të parazgjedhur, Default cookies, e detyrueshme për ta bërë këtë faqe të funksionojë.",
    "Loader.save": "Duke u ngarkuar...",
    "NotFound.backHome": "Kthehu tek faqja kryesore ",
    "NotFound.teaser": "Më fal ... diçka shkoi gabim. Faqja për të cilën po kërkon nuk ekziston.",
    "NotFound.Ooops": "Ooops",
    "PageAdmin.login": "login",
    "PageAdmin.please": "Ju lutem",
    "Footer.language": "language",
    "Blog.WrittenBy": "by",
    "Blog.WrittenOn": "Written on",
    "Downloads.download": "Download",
    "Share.shareOn": "Share on",
    "Share.share": "Share"
  }
};

export default translations;