import Cookies from 'js-cookie';

import {
  AvailableLanguage,
  AvailableLanguages,
  AvailableLanguagesExtended,
} from '@global-website/library';

import { availableLanguages } from '../Intl';

const resolveLanguage = (
  language: AvailableLanguagesExtended,
): AvailableLanguages | undefined => {
  if (language.indexOf('-') > -1) {
    const langArray = language.split('-');
    return langArray && langArray.length > 0
      ? (langArray[0] as AvailableLanguages)
      : undefined;
  }
  return;
};

const isLanguageAvailable = (code: AvailableLanguagesExtended): boolean => {
  return availableLanguages.some((language) => language.extendedCode === code);
};

const transformLanguageCode = (
  code: AvailableLanguages,
): AvailableLanguagesExtended => {
  let extended: AvailableLanguagesExtended = AvailableLanguagesExtended.En150;
  availableLanguages.forEach((lang) => {
    if (lang.code === code) {
      extended = lang.extendedCode;
    }
  });
  return extended;
};

export const assignLanguage = (
  language: AvailableLanguagesExtended,
): AvailableLanguagesExtended => {
  const resolvedLanguage = resolveLanguage(language) || AvailableLanguages.En;
  const selected = transformLanguageCode(resolvedLanguage);
  return (
    (isLanguageAvailable(selected) && selected) ||
    AvailableLanguagesExtended.En150
  );
};

interface IntlCookie {
  code: AvailableLanguage['code'];
  extendedCode: AvailableLanguage['extendedCode'];
  name: string;
}

export const storeIntl = (
  intl: AvailableLanguage,
  initial: boolean = false,
) => {
  const COOKIE_NAME = 'intl';
  const intlObject: IntlCookie = {
    code: intl.code,
    extendedCode: intl.extendedCode,
    name: intl.name,
  };

  const cookie = Cookies.get(COOKIE_NAME);

  if (cookie && cookie.length) {
    const parsedCookie = JSON.parse(cookie) as IntlCookie;

    if (parsedCookie.code !== intlObject.code && !initial) {
      Cookies.set(COOKIE_NAME, JSON.stringify(intlObject));
      return intlObject;
    }
    return parsedCookie;
  } else {
    Cookies.set(COOKIE_NAME, JSON.stringify(intlObject));
  }
  return intlObject;
};

export const getIntlObject = (
  extendedCode: AvailableLanguagesExtended,
  initial: boolean = false,
): false | AvailableLanguage => {
  const intlObject = availableLanguages.find(
    (lang) => lang.enabled && lang.extendedCode === extendedCode,
  );

  const stored = intlObject && storeIntl(intlObject, initial);

  if (stored?.code !== intlObject?.code) {
    return (
      availableLanguages.find(
        (lang) => lang.enabled && lang.extendedCode === stored?.extendedCode,
      ) || false
    );
  }

  return intlObject || false;
};
