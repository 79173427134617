import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import MESSAGES from './Messages';
import { Author, Recruitment } from '@global-website/library';

export const RecruitmentInformation: FunctionComponent<
  Recruitment & { contact?: Author }
> = ({ createdAt, contact, name, office }) => {
  const currentTime = new Date().getTime();
  const startTime = createdAt ? new Date(createdAt).getTime() : currentTime;
  const inPast = startTime <= currentTime;
  const email = (contact && contact.email) || 'recruiting@globalct.com';
  const intl = useIntl();

  return (
    <div className="section-detail-job-info mt-3 mb-3">
      <Row>
        <Col md={6}>
          <dl>
            <dt>
              <FormattedMessage {...MESSAGES.position} />:
            </dt>
            <dd>{name}</dd>
            {office && (
              <>
                <dt>
                  <FormattedMessage {...MESSAGES.location} />:
                </dt>
                <dd>{office}</dd>
              </>
            )}
            {startTime && (
              <>
                <dt>
                  <FormattedMessage {...MESSAGES.start} />:
                </dt>
                <dd>
                  {inPast ? (
                    <FormattedMessage {...MESSAGES.fromNowOn} />
                  ) : (
                    createdAt
                  )}
                </dd>
              </>
            )}
          </dl>
        </Col>
        {contact && (
          <Col md={4} className="offset-md-2">
            <dl>
              <dt>
                <FormattedMessage {...MESSAGES.contactPerson} />:
              </dt>
              <dd>
                {contact.firstName} {contact.lastName}
              </dd>
              <dt>
                <FormattedMessage {...MESSAGES.eMail} />:
              </dt>
              <dd>
                <a
                  href={`mailto:${email}`}
                  title={`${intl.formatMessage({
                    ...MESSAGES.writeTo,
                  })} ${email}`}
                >
                  {email}
                </a>
              </dd>
              <dt>
                <FormattedMessage {...MESSAGES.phone} />:
              </dt>
              <dd>
                <a
                  href={`tel:${contact.phone}`}
                  title={`${intl.formatMessage({ ...MESSAGES.phone })} ${
                    contact.phone
                  }`}
                >
                  {contact.phone}
                </a>
              </dd>
            </dl>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RecruitmentInformation;
