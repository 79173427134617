import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { BlogList as BlogListProps } from '@global-website/library';

import { GlobalState, PublicRequestKeys } from '../../../../Library';
import BlogDetail from './BlogDetail';
import BlogListComponent from './BlogList';

export interface BlogRouteProps extends BlogListProps {
  [PublicRequestKeys.Router]: GlobalState[PublicRequestKeys.Router];
  dispatch: unknown;
  path: string;
  routeProps?: RouteComponentProps;
  isAdmin: boolean;
}

export const BlogRouteBase: React.FC<BlogRouteProps> = (props) => {
  const { dispatch, path, router, routeProps, ...rest } = props;

  const [location, setLocation] = useState(router.location);

  useEffect(() => {
    if (router.location.key !== location.key) {
      setLocation(router.location);
    }
  }, [location, router.location, setLocation]);

  if (path === location.pathname) {
    return <BlogListComponent {...rest} path={path} />;
  }

  if (location.pathname.startsWith(path) && routeProps) {
    return <BlogDetail isAdmin={true} routeProps={routeProps} path={path} />;
  }

  return null;
};

export const BlogRoute = connect((state: GlobalState) => ({
  [PublicRequestKeys.Router]: state[PublicRequestKeys.Router],
}))(BlogRouteBase);

export default BlogRoute;
