import React from 'react';
import Slider, { Settings } from 'react-slick';
import { Col, Container, Row } from 'reactstrap';

import {
  BreakpointTypes,
  EmployerTestimonal,
  EmployerTestimonalList as EmployerTestimonalListProps,
  MediaSize,
} from '@global-website/library';

import { MarkdownRenderer, useConfig } from '../../../../Library';
import { Image } from '../../Image';

export const EmployerTestimonalItem: React.FC<EmployerTestimonal> = (props) => {
  const item = props as EmployerTestimonal;
  let colText = 7;
  let colImage = 5;

  switch (item.mediaSize) {
    case MediaSize.Large:
      colText = 5;
      colImage = 7;
      break;
    case MediaSize.Small:
      colText = 9;
      colImage = 3;
      break;
    case MediaSize.Default:
    default:
      break;
  }

  return (
    <div className="employer-testimonal-list-item">
      <Container fluid={true}>
        <Row className="align-items-center">
          <Col md={colImage}>
            <Image {...item.image}></Image>
          </Col>
          <Col md={colText} className="employer-testimonal-list-item-data">
            <div className="employer-testimonal-list-item-name">
              <h3>{item.fullName}</h3>
            </div>
            {item.employerPosition && (
              <div className="employer-testimonal-list-item-position">
                <h4>{item.employerPosition}</h4>
              </div>
            )}
            <div className="employer-testimonal-list-item-content lead">
              <MarkdownRenderer content={item.content} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const EmployerTestimonalList: React.FunctionComponent<
  EmployerTestimonalListProps
> = (props) => {
  const { items, title, lead } = props;

  const config = useConfig();
  const lg = config.breakpoints.find(
    (breakpoint) => breakpoint.type === BreakpointTypes.Md,
  );

  const slickSettings: Settings = {
    draggable: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: lg?.size || 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="section section-employer-testimonal-list">
      <Container fluid={true}>
        <Row>
          <Col sm={6} className="offset-sm-3 text-center">
            <h2 className="mb-1">{title}</h2>
            {lead && <p className="lead mb-section">{lead}</p>}
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        {items && items.length > 0 && (
          <Slider {...slickSettings}>
            {items.map(
              (item, index) =>
                (item && (
                  <EmployerTestimonalItem
                    {...item}
                    key={`employer_testimonal_slide_item_${item.id}_${index}`}
                  />
                )) ||
                null,
            )}
          </Slider>
        )}
      </Container>
    </section>
  );
};

export default EmployerTestimonalList;
