import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';

import { TextWithVideo as TextWithVideoProps } from '@global-website/library';

import { TextVideoHorizontal } from './TextVideoHorizontal';
import { MarkdownRenderer } from '../../../../Library';

export const TextWithVideoBottom: React.FC<
  TextWithVideoProps & { forJobs?: boolean }
> = ({
  __component,
  content,
  isFluid,
  forJobs,
  videoPosition,
  mediaSize,
  url,
}) => {
  const className = __component.replace(/\./g, '-').toLowerCase();

  return (
    <div className={classNames(className, `${className}-bottom`)}>
      <div
        className={classNames({
          container: !forJobs && !isFluid,
          'container-fluid': !forJobs && isFluid,
        })}
      >
        <Row>
          <Col
            md={!forJobs ? 10 : 12}
            className={classNames({ 'offset-md-1': !forJobs })}
          >
            <MarkdownRenderer content={content} />
          </Col>
        </Row>
        {url && (
          <TextVideoHorizontal
            mediaSize={mediaSize}
            videoPosition={videoPosition}
            url={url}
          />
        )}
      </div>
    </div>
  );
};
