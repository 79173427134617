import React from 'react';

import {
  MediaPosition,
  TextWithVideo as TextWithVideoProps,
} from '@global-website/library';

import { TextWithVideoBottom } from './TextWithVideoBottom';
import { TextWithVideoLeft } from './TextWithVideoLeft';
import { TextWithVideoRight } from './TextWithVideoRight';
import { TextWithVideoTop } from './TextWithVideoTop';

export const TextWithVideo: React.FC<
  TextWithVideoProps & { forJobs?: boolean }
> = (props) => {
  if (!props.active) {
    return null;
  }

  switch (props.videoPosition) {
    case MediaPosition.Top:
      return <TextWithVideoTop {...props} />;
    case MediaPosition.Right:
      return <TextWithVideoRight {...props} />;
    case MediaPosition.Bottom:
      return <TextWithVideoBottom {...props} />;
    case MediaPosition.Left:
      return <TextWithVideoLeft {...props} />;
    default:
      return null;
  }
};
