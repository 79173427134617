import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
  download: {
    id: 'Downloads.download',
    defaultMessage: 'Download',
  },
});

export default MESSAGES;
