import {
  AvailableLanguages,
  ContentKeys,
  Page,
  RequestStatus,
} from '@global-website/library/build';
import {
  GlobalState,
  PublicRequestKeys,
} from '@global-website/library/build/Redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from 'react-use';

export type LocalizationRoutes = { [key in AvailableLanguages]?: string };

export const localizationRoutes = (
  locale: AvailableLanguages,
  isRoot: boolean,
  pages?: (Page | null)[],
  parentPath?: LocalizationRoutes,
): LocalizationRoutes[] => {
  const routes: LocalizationRoutes[] = isRoot
    ? [
        {
          [AvailableLanguages.De]: '/',
          [AvailableLanguages.En]: '/',
          [AvailableLanguages.Sq]: '/',
        },
      ]
    : [];

  if (!pages) {
    return [];
  }
  pages?.forEach((page) => {
    if (!page || (page && !page.active)) {
      return false;
    }

    const paths: LocalizationRoutes = {
      [locale]: ((parentPath && parentPath[locale]) || '') + `/${page.slug}`,
    };

    // console.log(parentPath);

    if (page.localizations) {
      page.localizations.forEach(
        (localization) =>
          localization.locale &&
          Object.assign(paths, {
            [localization.locale]: `${
              (parentPath && parentPath[localization.locale]) || ''
            }/${localization.slug}`,
          }),
      );
    }

    routes.push(paths);

    if (page.pages && page.pages.length) {
      const subRoutes = localizationRoutes(locale, false, page.pages, paths);
      if (subRoutes && subRoutes.length) {
        routes.push(...subRoutes);
      }
    }

    if (page.content && page.content.length) {
      page.content.forEach((content) => {
        if (content) {
          switch (content.__component) {
            case ContentKeys.BranchList:
            case ContentKeys.JobList:
            case ContentKeys.RecruitmentList:
            case ContentKeys.ServiceList:
            case ContentKeys.BlogList:
              let contentPath: LocalizationRoutes = {};
              Object.keys(paths).forEach((key) => {
                const path = paths[key as keyof LocalizationRoutes];
                Object.assign(contentPath, {
                  [key]: `${path || ''}/:slug[${content.__component}]`,
                });
              });
              routes.push(contentPath);
              break;
            default:
              break;
          }
        }
      });
    }
  });

  return routes;
};

export const useTranslationRoutes = (): LocalizationRoutes[] => {
  const {
    result: pages,
    hash,
    status,
  } = useSelector<GlobalState, GlobalState[PublicRequestKeys.Pages]>(
    (state) => state[PublicRequestKeys.Pages],
  );

  const { code } = useSelector<
    GlobalState,
    GlobalState[PublicRequestKeys.Locals]
  >((state) => state[PublicRequestKeys.Locals]);

  const prevCode = usePrevious(code);
  const prevHash = usePrevious(hash);

  const [translationRoutes, setTranslationRoutes] = useState(
    localizationRoutes(code, true, pages),
  );

  useEffect(() => {
    if (
      prevCode !== code ||
      (status === RequestStatus.Loaded && prevHash !== hash)
    ) {
      setTranslationRoutes(localizationRoutes(code, true, pages));
    }
  }, [code, hash, prevCode, prevHash, pages, status]);

  return translationRoutes;
};
