import type { DownloadListSelected as DownloadListSelectedProps } from '@global-website/library/build';
import React, { FunctionComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { DownloadItem } from '.';
import { DownloadListTitle } from './DownloadListTitle';

export const DownloadListSelected: FunctionComponent<
  DownloadListSelectedProps
> = ({ __component, title, lead, downloads, id }) => {
  const className = __component.replace(/\./g, '-').toLowerCase();
  return (
    <section className="section section-download section-download-selected">
      <div className={className}>
        <Container fluid={false}>
          <Row>
            <Col>
              <DownloadListTitle {...{ title, lead }} />
              {downloads && downloads.length && (
                <div className="download-list">
                  {downloads.map((download, index) => (
                    <DownloadItem
                      key={`download_list_selected_${id}_${index}`}
                      {...download}
                      isLast={index + 1 === downloads.length}
                    />
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};
