import React, { FunctionComponent } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';

import { isProduction } from '@global-website/library';

export const initGTM = (id?: string) => {
  if (isProduction) {
    const tagManagerArgs: TagManagerArgs = {
      gtmId: id || 'GTM-TC487TQ',
    };

    setTimeout(() => {
      TagManager.initialize(tagManagerArgs);
    }, 500);
  }
};

interface GoogleTagManagerProps {
  id?: string;
}

export const GoogleTagManager: FunctionComponent<GoogleTagManagerProps> = ({
  id,
}) => {
  initGTM(id);
  return <></>;
};
