import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
  loading: {
    id: 'Loader.save',
    defaultMessage: 'Loading...',
  },
});

export default MESSAGES;
