import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
  backHome: {
    id: 'NotFound.backHome',
    defaultMessage: 'Back to Homepage',
  },
  teaser: {
    id: 'NotFound.teaser',
    defaultMessage:
      "Sorry ... something went wrong. The page you were looking for doesn't exist.",
  },
  ooops: {
    id: 'NotFound.Ooops',
    defaultMessage: 'Ooops',
  },
  login: {
    id: 'PageAdmin.login',
    defaultMessage: 'login',
  },
  please: {
    id: 'PageAdmin.please',
    defaultMessage: 'Please',
  },
});

export default MESSAGES;
