import React from 'react';
import ReactMarkdown from 'react-markdown';
import { AssetRenderer } from './AssetRenderer';
import { LinkRenderer } from './LinkRenderer';

export const MarkdownRenderer: React.FunctionComponent<{
  content: string;
  className?: string;
}> = ({ className, content }) => (
  <ReactMarkdown
    className={className}
    children={content}
    components={{
      a: (props) => <LinkRenderer {...props} />,
      img: (props) => <AssetRenderer {...props} />,
    }}
  />
);
