import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ReactReduxRequestDispatch,
  requestAction,
  RequestStatus,
} from '@global-website/library';

import { GlobalState, PublicRequestKeys, useConfig } from '../../../Library';

export const useHomepage = (hostname: string, update: boolean = false) => {
  const dispatch = useDispatch<ReactReduxRequestDispatch>();
  const homepage = useSelector<GlobalState, GlobalState['homepage']>(
    (state) => state[PublicRequestKeys.Homepage],
  );
  const config = useConfig();
  const url = config.createApiUrl(config.api.config);
  url.pathname = `homepages/find-by-url/${hostname}`;

  useEffect(() => {
    if (!update && homepage.status === RequestStatus.Initial) {
      dispatch(
        requestAction.load(PublicRequestKeys.Homepage, {
          method: 'GET',
          url: url.href,
        }),
      );
    }

    if (update && homepage.status === RequestStatus.Loaded) {
      dispatch(
        requestAction.update(PublicRequestKeys.Homepage, {
          method: 'GET',
          url: url.href,
        }),
      );
    }
  }, [dispatch, homepage, update, url.href]);

  return homepage;
};

export default useHomepage;
