import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import {
  RecruitmentList,
  requestAction,
  RequestStatus,
} from '@global-website/library';

import { GlobalState, PublicRequestKeys, useConfig } from '../../../../Library';
import { RecruitmentListLatestContent } from './RecruitmentListLatestContent';

export const RecruitmentListComponent: FunctionComponent<
  RecruitmentList & { listALl: boolean }
> = (props) => {
  const config = useConfig();
  const dispatch = useDispatch();
  const { status, result: recruitments } = useSelector<
    GlobalState,
    GlobalState[PublicRequestKeys.Recruitments]
  >((state) => state.recruitments);

  useEffect(() => {
    if (!config) {
      return;
    }

    if (status === RequestStatus.Initial) {
      const url = config.api.createApiUrl(config.api.config);
      url.pathname = 'recruitments';
      url.search = `_sort=createdAt:ASC${
        (!props.listALl && '&_limit=4') || ''
      }`;
      dispatch(
        requestAction.load(PublicRequestKeys.Recruitments, {
          url: url.href,
        }),
      );
    }
  }, [config, dispatch, status, props.homepage, props.listALl]);

  useUnmount(() => {
    dispatch(requestAction.clear(PublicRequestKeys.Recruitments));
  });

  return (
    (status === RequestStatus.Loaded && recruitments && (
      <RecruitmentListLatestContent
        list={props}
        recruitments={recruitments}
        listAll={props.listALl}
      />
    )) ||
    null
  );
};

export default RecruitmentListComponent;
