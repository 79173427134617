import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { RecruitmentList as RecruitmentListProps } from '@global-website/library';

import { GlobalState, PublicRequestKeys } from '../../../../Library';
import { RecruitmentListComponent } from './RecruitmentList';

export interface RecruitmentRouteProps extends RecruitmentListProps {
  [PublicRequestKeys.Router]: GlobalState[PublicRequestKeys.Router];
  dispatch: unknown;
  isAdmin: boolean;
  path: string;
  routeProps?: RouteComponentProps;
}

export const RecruitmentRouteBase: React.FC<RecruitmentRouteProps> = (
  props,
) => {
  const { dispatch, isAdmin, path, router, routeProps, ...rest } = props;

  const [location, setLocation] = useState(router.location);

  useEffect(() => {
    if (router.location.key !== location.key) {
      setLocation(router.location);
    }
  }, [location, router.location, setLocation]);

  if (path === location.pathname) {
    return <RecruitmentListComponent {...rest} listALl={true} />;
  }

  return null;
};

export const RecruitmentRoute = connect((state: GlobalState) => ({
  [PublicRequestKeys.Router]: state[PublicRequestKeys.Router],
}))(RecruitmentRouteBase);

export default RecruitmentRoute;
