import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import {
  AvailableLanguage,
  requestAction,
  selectLanguage,
} from '@global-website/library';

import {
  availableLanguages,
  GlobalState,
  PublicRequestKeys,
} from '../../../Library';
import MESSAGES from './Messages';
import { useTranslationRoutes } from '../../Hooks/Routes/useTranslationRoutes';
import { useHistory } from 'react-router-dom';

interface LanguageProps {}

export const Language: FC<LanguageProps> = () => {
  const history = useHistory();
  const locals = useSelector<
    GlobalState,
    GlobalState[PublicRequestKeys.Locals]
  >((state) => state[PublicRequestKeys.Locals]);

  const translatedRoutes = useTranslationRoutes();

  const { location } = useSelector<
    GlobalState,
    GlobalState[PublicRequestKeys.Router]
  >((state) => state[PublicRequestKeys.Router]);
  const [routes, setRoute] = useState(
    translatedRoutes.find((route) => route[locals.code] === location.pathname),
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const prevExtendedCode = usePrevious(locals.extendedCode);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const changeLanguage = useCallback(
    (language: AvailableLanguage) => {
      dispatch(selectLanguage(language.extendedCode));

      const nextRoute = translatedRoutes.find(
        (route) => route[locals.code] === location.pathname,
      );

      if (location.pathname !== (nextRoute && nextRoute[language.code])) {
        setRoute(nextRoute);
        history.push((nextRoute && nextRoute[language.code]) || '/');
      }
    },
    [dispatch, history, locals.code, location.pathname, translatedRoutes],
  );

  useEffect(() => {
    if (prevExtendedCode && locals.extendedCode !== prevExtendedCode) {
      Object.keys(PublicRequestKeys).forEach((key) => {
        const value = (PublicRequestKeys as any)[key] as PublicRequestKeys;
        dispatch(requestAction.clear(value));
      });
    }

    const nextRoute = translatedRoutes.find(
      (route) => route[locals.code] === location.pathname,
    );
    if (
      nextRoute &&
      nextRoute[locals.code] !== (routes && routes[locals.code])
    ) {
      setRoute(nextRoute);
    }
  }, [
    dispatch,
    history,
    locals.code,
    locals.extendedCode,
    location.pathname,
    prevExtendedCode,
    routes,
    translatedRoutes,
  ]);

  return (
    (
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className={'dd-language'}>
        <DropdownToggle className={'toggle'}>
          <i className="fa fa-globe" />{' '}
          <FormattedMessage {...MESSAGES.language} />
        </DropdownToggle>
        <DropdownMenu>
          {availableLanguages.map((lang, index) => {
            return (
              <DropdownItem
                key={`dropdown_lang_menu_${index}`}
                onClick={(e) => changeLanguage(lang)}
                disabled={lang.code === locals.code}
              >
                {lang.name}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    ) || null
  );
};

export default Language;
