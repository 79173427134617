import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
  shareOn: {
    id: 'Share.shareOn',
    defaultMessage: 'Share on',
  },
  share: {
    id: 'Share.share',
    defaultMessage: 'Share',
  },
});

export default MESSAGES;
