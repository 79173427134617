import React from 'react';
import ReactPlayer from 'react-player';

import { MediaPosition, MediaSize } from '@global-website/library';

export interface TextVideoProps {
  videoPosition: MediaPosition;
  mediaSize: MediaSize;
  url: string;
}

export const TextVideo: React.FC<TextVideoProps> = ({
  videoPosition,
  mediaSize,
  url,
}) => {
  return (
    <>
      <div className="player-wrapper">
        <ReactPlayer
          width="100%"
          height="100%"
          className="react-player"
          url={url}
        />
      </div>
    </>
  );
};
