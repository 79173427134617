import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  BlogList,
  Image as ImageProps,
  NumberOfBlog,
  NumberOfBlogNr,
  ReactReduxRequestDispatch,
  requestAction,
  RequestStatus,
  WithConfigProps,
} from '@global-website/library';

import {
  GlobalState,
  PublicRequestKeys,
  withConfig,
} from '../../../../Library';
import { Image } from '../../Image';

type BlogListType = BlogList & WithConfigProps;

export interface BlogListProps extends BlogListType {
  [PublicRequestKeys.Blogs]: GlobalState[PublicRequestKeys.Blogs];
  dispatch: ReactReduxRequestDispatch;
  path: string;
}

export class BlogListComponentBase extends React.Component<BlogListProps> {
  componentDidMount() {
    const { config, dispatch, blogs } = this.props;
    const numberOfBlog = this.props.NumberOfBlog;
    let nrBlogToDisplay = -1;
    if (numberOfBlog === NumberOfBlog.three) {
      nrBlogToDisplay = NumberOfBlogNr.three;
    } else if (numberOfBlog === NumberOfBlog.five) {
      nrBlogToDisplay = NumberOfBlogNr.five;
    } else if (numberOfBlog === NumberOfBlog.eight) {
      nrBlogToDisplay = NumberOfBlogNr.eight;
    } else if (numberOfBlog === NumberOfBlog.nine) {
      nrBlogToDisplay = NumberOfBlogNr.nine;
    }

    if (blogs.status === RequestStatus.Initial && config) {
      const url = config.api.createApiUrl(config.api.config);
      url.pathname = 'blogs';
      url.search = `?_limit=${nrBlogToDisplay}&_sort=publishDate:DESC`;
      dispatch(
        requestAction.load(PublicRequestKeys.Blogs, {
          url: url.href,
        }),
      );
    }
  }

  componentWillUnmount() {
    const { dispatch, blogs } = this.props;

    if (blogs.status === RequestStatus.Loaded) {
      dispatch(requestAction.clear(PublicRequestKeys.Blogs));
    }
  }

  render() {
    const { blogs } = this.props;
    const result = this.props.blogs.result;

    var months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return (
      <>
        {blogs.status === RequestStatus.Loaded && result && (
          <Container fluid={true} className="p-md-0 blog-container">
            {result.map((item, index) => {
              const date = new Date(item.publishDate);
              var monthName = months[date.getMonth()];
              const type = index % 9;
              return (
                <React.Fragment key={`blog_list_item_${item.id}_${index}`}>
                  <Link
                    to={encodeURI(`${this.props.path}/${item.slug}`)}
                    className={`blog-${type}`}
                  >
                    {type !== 2 && type !== 5 && (
                      <Image
                        className="blog-item-img"
                        {...(item.previewImage as ImageProps)}
                      />
                    )}

                    <div className="blog-item-text-content">
                      <div className="blog-item-text-date">
                        {monthName} {date.getFullYear()}
                      </div>
                      <div className="blog-item-text-title">{item.title}</div>
                    </div>
                  </Link>
                </React.Fragment>
              );
            })}
          </Container>
        )}
      </>
    );
  }
}

export const BlogListComponent = connect((state: GlobalState) => ({
  [PublicRequestKeys.Blogs]: state[PublicRequestKeys.Blogs],
}))(withConfig(BlogListComponentBase));

export default BlogListComponent;
