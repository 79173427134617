import { DownloadContent } from '@global-website/library';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';

export const DownloadListTitle: FunctionComponent<
  Pick<DownloadContent, 'title'> & Pick<DownloadContent, 'lead'>
> = ({ lead, title }) => {
  return (
    ((title || lead) && (
      <div className="download-list-title">
        <Row>
          <Col className="text-center mb-5">
            {title && <h3 className="h2 mb-0">{title}</h3>}
            {lead && <p className="lead">{lead}</p>}
          </Col>
        </Row>
      </div>
    )) ||
    null
  );
};
