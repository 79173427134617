import './Assets/Sass/index.scss';
import './Library/Fonts/amplesoft';
import './Library/Fonts/icons';
import '@fortawesome/fontawesome-free/css/all.css';
import 'core-js/es/map';
import 'core-js/es/object/assign';
import 'core-js/es/object/set-prototype-of';
import 'core-js/es/object/values';
import 'core-js/es/set';
import 'raf/polyfill';
import 'react-datepicker/dist/react-datepicker.css';

import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { connect, Provider } from 'react-redux';

import {
  Alerts,
  AvailableLanguage,
  defaultConfig,
} from '@global-website/library';

import App from './App/App';
import {
  ConfigProvider,
  configureStore,
  GlobalState,
  history,
} from './Library';
import reportWebVitals from './reportWebVitals';

const AppWithTranslationWithoutState: React.FC<{
  locals: AvailableLanguage;
}> = ({ locals }) => (
  <IntlProvider {...locals.intl}>
    <App />
    <Alerts />
  </IntlProvider>
);

const AppWithTranslation = connect((state: GlobalState) => ({
  locals: state.locals,
}))(AppWithTranslationWithoutState);

const AppWithState = () => (
  <Provider store={configureStore()}>
    <ConnectedRouter history={history}>
      <AppWithTranslation />
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(
  <ConfigProvider config={defaultConfig()}>
    <AppWithState />
  </ConfigProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
