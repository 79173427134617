import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import {
  ApiProtocol,
  Homepage,
  Image,
  isDevelopment,
  Meta,
} from '@global-website/library';

import { GlobalState, PublicRequestKeys, useConfig } from '../../../Library';

export interface PageMetaProps {
  author?: string;
  homepage: Homepage;
  image?: Image;
  meta?: (Meta | null)[] | null;
  path?: string;
  title: string;
  useMicro?: boolean;
}

export const PageMeta: React.FC<PageMetaProps> = ({
  author,
  homepage,
  image,
  meta,
  path,
  title,
  useMicro,
}) => {
  const config = useConfig();
  const { location } = useSelector<
    GlobalState,
    GlobalState[PublicRequestKeys.Router]
  >((state) => state[PublicRequestKeys.Router]);
  const isLocal =
    isDevelopment && window.location.hostname === ('localhost' || '127.0.0.1')
      ? true
      : false;

  const baseUrl = isLocal
    ? config.createApiUrl({
        url: config.env.host,
        port: config.env.port,
        protocol: config.env.protocol,
        path: path || '',
      })
    : config.createApiUrl({
        url: homepage.url,
        port: undefined,
        protocol: ApiProtocol.Https,
        path: path || '',
      });

  const isHome = location.pathname === ('/' || '');

  const lang = useSelector<GlobalState, GlobalState[PublicRequestKeys.Locals]>(
    (state) => state.locals,
  );

  const editor = `${
    (homepage?.admin &&
      `${homepage.admin.firstName} ${homepage.admin.lastName}`) ||
    ''
  }`;

  const dynamicTitle = isHome ? homepage.title : `${homepage.title} | ${title}`;
  const imageUrl = image && config.api?.url;
  if (imageUrl && image) {
    imageUrl.pathname = image.url;
  }

  return (
    <Helmet htmlAttributes={{ lang: lang.code }}>
      <base href={baseUrl.href} />
      <title>{dynamicTitle}</title>
      <meta name="author" content={author ? author : editor} />
      <meta property="og:author" content={author ? author : editor} />
      {meta &&
        meta.map(
          (data, index) =>
            (data && (
              <meta
                key={`helmet_meta_${data.name}_${index}`}
                name={data.name}
                content={data.content}
              />
            )) ||
            null,
        )}
      {useMicro &&
        meta &&
        meta.map(
          (data, index) =>
            (data && (
              <meta
                key={`helmet_meta_${data.name}_${index}`}
                property={`og:${data.name}`}
                content={data.content}
              />
            )) ||
            null,
        )}
      {useMicro && imageUrl && (
        <meta property="og:image" content={imageUrl.href} />
      )}
    </Helmet>
  );
};

export default PageMeta;
