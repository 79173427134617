import React, { FC } from 'react';
import { Col, Row } from 'reactstrap';

import { AuthorListItem as AuthorListItemProps } from '@global-website/library';

import { Image } from '../Image';

export const ContactBoxItem: FC<AuthorListItemProps> = (props) => {
  const contact = props;
  return (
    <>
      <Row className="p-0 m-0">
        <Col className="p-0 m-0">
          {contact.author.image && (
            <Image className="contact-img" {...contact.author.image} />
          )}
        </Col>
      </Row>
      <Row className="p-0 m-0">
        <Col md={11} className="p-3 p-sm-5 m-0">
          <div className="contact-text offset-md-1">
            <h3>
              {contact.author.firstName} {contact.author.lastName}
              {contact.author.jobTitle && (
                <small>{contact.author.jobTitle}</small>
              )}
            </h3>
            <p className="mb-5">{contact.author.shortDescription}</p>
            <p className="mb-4 contact-data">
              <a href={`tel:${contact.author.phone}`}>
                <i className="icon-phone" />
                {contact.author.phone}
              </a>{' '}
              <br />
              <a href={`mailto:${contact.author.email}`}>
                <i className="icon-mail" /> {contact.author.email}
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ContactBoxItem;
