import React from 'react';

export const BlogPerson: React.FC = () => {
  return (
    <div className="img-wrap bg-light">
      <figure>
        <svg
          width="752pt"
          height="752pt"
          version="1.1"
          viewBox="0 0 752 752"
          xmlns="http://www.w3.org/2000/svg"
          className="img-fluid"
        >
          <g>
            <path d="m536.94 442.04h-64.168c-26.195 21.191-58.094 33.672-92.496 33.672-34.391 0-66.281-12.484-92.48-33.672h-64.16c-26.465 0-48.117 21.645-48.117 48.109v101.33h409.54v-101.33c-0.011719-26.469-21.664-48.113-48.113-48.113z" />
            <path d="m380.27 146.32c-70.402 0-127.48 60.48-127.48 144.17 0 83.695 57.082 151.55 127.48 151.55 70.426 0 127.5-67.855 127.5-151.55-0.003907-83.688-57.078-144.17-127.5-144.17z" />
          </g>
        </svg>
      </figure>
    </div>
  );
};
