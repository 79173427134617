import React from 'react';
import Slider, { Settings } from 'react-slick';
import { Col, Container, Row } from 'reactstrap';

import {
  BreakpointTypes,
  HomepageItem,
  HomepageList as HomepageListProps,
} from '@global-website/library';

import { CollapseHover, useConfig } from '../../../../Library';
import { Image } from '../../Image';

export const HomepageSlideItem: React.FC<HomepageItem> = (props) => {
  const item = props as HomepageItem;

  const linkTo = item.homepage.url.startsWith('http')
    ? item.homepage.url
    : 'https://' + item.homepage.url;

  return (
    <a href={linkTo} className="homepage-slider-item">
      {item && item.homepage.image && (
        <Image className="homepage-slider-img" {...item.homepage.image} />
      )}

      <CollapseHover collapseChildren={item.homepage.url}>
        <>
          <h2 className="homepage-slider-title">{item.title}</h2>
        </>
      </CollapseHover>
    </a>
  );
};

export const HomepageList: React.FC<HomepageListProps> = (props) => {
  const { homepages, title } = props;

  const config = useConfig();

  const lg = config.breakpoints.find(
    (breakpoint) => breakpoint.type === BreakpointTypes.Lg,
  );

  const slickSettings: Settings = {
    draggable: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: lg?.size || 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="homepage-slider">
      <Container fluid={true}>
        <Row>
          <Col sm={6} className="offset-sm-3 text-center">
            <h2 className="mb-1  mb-section">{title}</h2>
          </Col>
        </Row>
      </Container>
      {homepages && homepages.length > 0 && (
        <div className="homepage-slider-wrap">
          <Container fluid={true}>
            <Row>
              <Col>
                <Slider {...slickSettings}>
                  {homepages.map(
                    (homepage, index) =>
                      (homepage && (
                        <HomepageSlideItem
                          {...homepage}
                          key={`homepage_slide_item_${homepage.id}_${index}`}
                        />
                      )) ||
                      null,
                  )}
                </Slider>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </section>
  );
};
