import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

import {
  BreakpointTypes,
  CompetenceList,
  Site,
  WithConfigProps,
} from '@global-website/library';

import kernKompetenze from '../../../../Assets/Images/gct_kernkompetenzen-circle.svg';
import {
  GlobalState,
  PublicRequestKeys,
  withConfig,
} from '../../../../Library';
import { CompetenceItem, CompetenceListSM } from './CompetenceItem';

type CompetenceListProps = CompetenceList & WithConfigProps;

export class CompetenceListComponentBase extends React.Component<CompetenceListProps> {
  render() {
    const { title, competenceList, config } = this.props;

    const sm = config
      ? config.breakpoints.find(
          (breakpoint) => breakpoint.type === BreakpointTypes.Sm,
        )
      : undefined;
    const size = sm?.size ? sm?.size : 768;

    const digitalCompetenceList = competenceList.filter(
      (x) => x.site.toLowerCase() === Site.digital.toLowerCase(),
    );
    const consultingCompetenceList = competenceList.filter(
      (x) => x.site.toLowerCase() === Site.consulting.toLowerCase(),
    );

    return (
      <>
        {window.innerWidth >= size && (
          <section className="competence-section">
            <Container fluid={true} className="competence-container">
              <label
                className="text-center competence-title"
                style={{
                  backgroundImage: `url(${kernKompetenze})`,
                  backgroundRepeat: 'no-repeat',
                }}
              >
                {title}
              </label>
            </Container>
            <Container className="competence-container-list p-0">
              {
                <div className="selector">
                  <ul>
                    {competenceList.map((competence, index) => {
                      const rotate = index * 30 - 90;
                      const rotateLabel = rotate * -1;
                      return (
                        <>
                          <CompetenceItem
                            competence={competence}
                            rotate={rotate}
                            rotateLabel={rotateLabel}
                          ></CompetenceItem>
                        </>
                      );
                    })}
                  </ul>
                </div>
              }
            </Container>
          </section>
        )}
        {window.innerWidth < size && (
          <section className="competence-section">
            <Container fluid={true} className="competence-container">
              <Row>
                <Col sm={6} className="offset-sm-3 text-center">
                  <h2 className="mb-3">{title}</h2>
                </Col>
              </Row>
              {consultingCompetenceList && (
                <>
                  <CompetenceListSM
                    competences={consultingCompetenceList}
                    title={Site.consulting.toString()}
                  ></CompetenceListSM>
                </>
              )}
              {digitalCompetenceList && (
                <>
                  <CompetenceListSM
                    competences={digitalCompetenceList}
                    title={Site.digital.toString()}
                  ></CompetenceListSM>
                </>
              )}
            </Container>
          </section>
        )}
      </>
    );
  }
}

export const CompetenceListComponent = connect((state: GlobalState) => ({
  [PublicRequestKeys.Competences]: state[PublicRequestKeys.Competences],
}))(withConfig(CompetenceListComponentBase));

export default CompetenceListComponent;
