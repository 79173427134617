import classnames from 'classnames';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import {
  BreakpointTypes,
  Page,
  Tab as TabPageProps,
  TabItem,
  TabType,
} from '@global-website/library';

import { useConfig } from '../../../../Library';
import { Image } from '../../Image';
import MESSAGES from './Messages';
import TabItemPage from './TabItemPage';

export interface TabButtonProps {
  className?: any;
  style?: any;
  onClick?: any;
  customClassName?: string;
}

export const TabPage: React.FunctionComponent<TabPageProps> = (props) => {
  const { tabItem, type } = props;
  const [activeTab, setActiveTab] = useState<number>(
    (tabItem && tabItem.length > 0 && tabItem[0]?.id) || 1,
  );

  const config = useConfig();
  const intl = useIntl();

  const lg = config.breakpoints.find(
    (breakpoint) => breakpoint.type === BreakpointTypes.Lg,
  );

  const slickSettings: Settings = {
    draggable: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    nextArrow: <SlickCustomArrow customClassName="tab-slider-arrow-next" />,
    prevArrow: <SlickCustomArrow customClassName="tab-slider-arrow-prev" />,
    responsive: [
      {
        breakpoint: lg?.size || 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const toggle = (id: TabItem<Page>['id']) => {
    if (activeTab !== id) setActiveTab(id);
  };

  return tabItem && tabItem.length > 0 ? (
    type === TabType.TabForm ? (
      <section className="tab-section">
        <div className="tab-section-nav">
          <Nav>
            {tabItem.map((tab, index) => {
              return tab ? (
                <NavItem
                  key={`tab_page_nav_${tab.id}_${index}`}
                  className={classnames({ active: activeTab === tab.id })}
                >
                  <NavLink
                    className={classnames({ active: activeTab === tab.id })}
                    onClick={() => {
                      toggle(tab.id);
                    }}
                  >
                    {tab.icon && (
                      <span className="nav-icon">
                        <i className={`icon-${tab.icon || 'globe'}`} />
                      </span>
                    )}
                    <span className="nav-text">
                      {tab.title}
                      <i className="icon icon-arrow-right" />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null;
            })}
          </Nav>
        </div>
        <div className="tab-section-content">
          <TabContent activeTab={activeTab}>
            {tabItem.map((tab, index) => {
              return tab ? (
                <TabPane
                  key={`tab_page_pane_${tab.id}_${index}`}
                  tabId={tab.id}
                >
                  {tab.media && (
                    <Image className="tab-section-background" {...tab.media} />
                  )}
                  <div className="tab-section-text">
                    <h3>{tab.lead ? tab.lead : tab.title}</h3>
                    {tab.teaser && <p className="lead">{tab.teaser}</p>}
                    {tab.item && (
                      <Link
                        className={'btn btn-ghost btn-sized mt-3'}
                        to={`/${encodeURI(tab.item.slug)}`}
                        title={tab.item.title}
                      >
                        {tab.callToAction
                          ? tab.callToAction
                          : `${intl.formatMessage({ ...MESSAGES.goTo })} ${
                              tab.item.title
                            }`}
                      </Link>
                    )}
                  </div>
                </TabPane>
              ) : null;
            })}
          </TabContent>
        </div>
      </section>
    ) : (
      <section className="tab-slider">
        <Container fluid={true} className="tab-slider-container">
          <Slider {...slickSettings}>
            {tabItem.map(
              (tab, index) =>
                (tab && (
                  <TabItemPage
                    key={`tab_page_nav_${tab.id}_${index}`}
                    {...tab}
                  />
                )) ||
                null,
            )}
          </Slider>
        </Container>
      </section>
    )
  ) : null;
};

export const SlickCustomArrow: React.FC<TabButtonProps> = (props) => {
  const { className, style, onClick, customClassName } = props;
  let allClassName = className;
  if (customClassName != null) {
    allClassName += ' ' + customClassName;
  }

  return <div className={allClassName} style={style} onClick={onClick} />;
};

export default TabPage;
