import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { logoPos } from '../Navbar';
import Loader from './Loader';

interface LoaderMainProps {}

const LoaderMain: React.FunctionComponent<LoaderMainProps> = (props) => {
  return (
    <>
      <Loader />
      <div className="loader loader-main">
        <Container>
          <Row>
            <Col>
              <img src={logoPos} alt="" width={595} height={187.09} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LoaderMain;
