import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';

import { TextWithVideo as TextWithVideoProps } from '@global-website/library';

import { TextVideo } from './TextVideo';
import { MarkdownRenderer } from '../../../../Library';

export const TextWithVideoLeft: React.FC<
  TextWithVideoProps & { forJobs?: boolean }
> = ({
  __component,
  content,
  forJobs,
  isFluid,
  videoPosition,
  mediaSize,
  url,
}) => {
  const className = __component.replace(/\./g, '-').toLowerCase();

  return (
    <div className={classNames(className, `${className}-bottom`)}>
      <div
        className={classNames({
          container: !forJobs && !isFluid,
          'container-fluid': !forJobs && isFluid,
        })}
      >
        <Row>
          <Col
            md={!forJobs ? 10 : 12}
            className={classNames({ 'offset-md-1': !forJobs })}
          >
            <Row>
              <Col md={5}>
                {url && (
                  <TextVideo
                    mediaSize={mediaSize}
                    videoPosition={videoPosition}
                    url={url}
                  />
                )}
              </Col>
              <Col md={7}>
                <MarkdownRenderer content={content} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
