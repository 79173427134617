import React from 'react';
import { Competence } from '@global-website/library';

export interface CompetenceListItemSMProps {
  competences: Competence[];
  title: string;
}

export interface CompetenceItemProps {
  competence: Competence;
  rotate: number;
  rotateLabel: number;
}

export const CompetenceItem: React.FC<CompetenceItemProps> = (props) => {
  const competencesItem = props as CompetenceItemProps;
  let padding_top = '';
  if (competencesItem.competence.title.length > 51)
    padding_top = 'competence-item-padding-top-2';
  else if (competencesItem.competence.title.length > 34)
    padding_top = 'competence-item-padding-top-3';
  return (
    <>
      <li style={{ transform: `rotate(${competencesItem.rotate}deg)` }}>
        <a href={`${competencesItem.competence.link}`}>
          <label
            style={{
              transform: `rotate(${competencesItem.rotateLabel}deg)`,
            }}
            className={`competence-item-${competencesItem.competence.site.toLowerCase()} ${padding_top}`}
          >
            {competencesItem.competence.title}
          </label>
        </a>
      </li>
    </>
  );
};

export const CompetenceListSM: React.FC<CompetenceListItemSMProps> = (
  props,
) => {
  const competencesListItem = props as CompetenceListItemSMProps;
  let padding_top = '';

  return (
    <>
      <h4 className="mt-4 text-center">{competencesListItem.title}</h4>
      <div className="competence-list">
        {competencesListItem &&
          competencesListItem.competences.map((competence, index) => {
            if (competence.title.length > 51)
              padding_top = 'competence-item-padding-top-2';
            else if (competence.title.length > 34)
              padding_top = 'competence-item-padding-top-3';
            return (
              <>
                <a href={`${competence.link}`}>
                  <div
                    className={`competence-item-${competence.site.toLowerCase()} ${padding_top}`}
                  >
                    <label
                      className={`competence-item-${competence.site.toLowerCase()}-title`}
                    >
                      {competence.title}
                    </label>
                    <div className="competence-item-seemore"> &gt; </div>
                  </div>
                </a>
              </>
            );
          })}
      </div>
    </>
  );
};
