export enum FormInputTypes {
  Checkbox = 'checkbox',
  Date = 'date',
  Email = 'email',
  Group = 'group',
  List = 'list',
  Multiple = 'multiple',
  MultipleGroup = 'multipleGroup',
  Number = 'number',
  Password= 'password',
  Select = 'select',
  Static = 'static',
  String = 'string',
  Text = 'text',
}

export enum FormValidationTypes {
  Active = 'active',
  Date = 'date',
  Email = 'email',
  Equal = 'equal',
  Group = 'array',
  Length = 'length',
  Number = 'number',
  Password = 'password',
  Phone = 'phone',
  Selected = 'selected',
  Url = 'url',
  Words = 'words',
}

export enum AutocompleteKeys {
  additionalName = 'additional-name',
  addressLevel1 = 'address-level1',
  addressLevel2 = 'address-level2',
  addressLevel3 = 'address-level3',
  addressLevel4 = 'address-level4',
  BDay = 'bday',
  BDayDay = 'bday-day',
  BDayMonth = 'bday-month',
  BDayYear = 'bday-year',
  CcAdditionalName = 'cc-additional-name',
  CcCsc = 'cc-csc',
  CcExp = 'cc-exp',
  CcExpMonth = 'cc-exp-month',
  CcExpYear = 'cc-exp-year',
  CcFamilyName = 'cc-family-name',
  CcGivenName = 'cc-given-name',
  CcName = 'cc-name',
  CcNumber = 'cc-number',
  CcType = 'cc-type',
  Country = 'country',
  CountryName = 'country-name',
  CurrentPassword = 'current-password',
  Email = 'email',
  FamilyName = 'family-name',
  GivenName = 'given-name',
  HonorificPrefix = 'honorific-prefix',
  HonorificSuffix = 'honorific-suffix',
  Impp = 'impp',
  Language = 'language',
  Name = 'name',
  NewPassword = 'new-password',
  Nickname = 'nickname',
  Off = 'off',
  On = 'on',
  OneTimeCode = 'one-time-code',
  Organization = 'organization',
  OrganizationTitle = 'organization-title',
  Photo = 'photo',
  PostalCode = 'postal-code',
  Sex = 'sex',
  StreetAddress = 'street-address',
  Tel = 'tel',
  TelAreaCode = 'tel-area-code',
  TelCountryCode = 'tel-country-code',
  TelExtension = 'tel-extension',
  TelLocal = 'tel-local',
  TelNational = 'tel-national',
  TransactionAmount = 'transaction-amount',
  TransactionCurrency = 'transaction-currency',
  Url = 'url',
  Username = 'username',
}