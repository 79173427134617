import React from 'react';
import { RouteComponentProps, RouteProps } from 'react-router-dom';

import { ContentKeys, Page } from '@global-website/library';

import { ContentResolverItem, PageResolver } from '../../Partials';

export interface PageRoutesProps {
  isAdmin: boolean;
  pages?: (Page | null)[];
  parentPath?: string;
}

export const createRoutes = (props: PageRoutesProps): RouteProps[] => {
  const { isAdmin, pages, parentPath } = props;

  const routes: RouteProps[] = [];

  if (!pages) {
    return routes;
  }

  pages.forEach((page, index) => {
    if (!page || (page && !page.active)) {
      return false;
    }

    const path = (parentPath || '') + `/${page.slug}`;

    routes.push({
      exact: true,
      path,
      render: () => (
        <PageResolver pageId={page.id} path={path} isAdmin={isAdmin} />
      ),
    });

    if (page.content && page.content.length > 0) {
      page.content.forEach((item, index) => {
        if (item) {
          switch (item.__component) {
            case ContentKeys.BranchList:
            case ContentKeys.JobList:
            case ContentKeys.RecruitmentList:
            case ContentKeys.ServiceList:
              routes.push({
                exact: true,
                path: `${path}/:slug`,
                render: (routeProps: RouteComponentProps) => (
                  <ContentResolverItem
                    {...item}
                    isAdmin={isAdmin}
                    path={path}
                    routeProps={routeProps}
                  />
                ),
              });
              break;
            case ContentKeys.BlogList:
              routes.push({
                exact: true,
                path: `${path}/:slug`,
                render: (routeProps: RouteComponentProps) => (
                  <ContentResolverItem
                    {...item}
                    isAdmin={isAdmin}
                    path={path}
                    routeProps={routeProps}
                  />
                ),
              });
              break;
            default:
              break;
          }
        }
      });
    }

    if (page.pages && page.pages.length > 0) {
      routes.push(
        ...createRoutes({ pages: page.pages, parentPath: path, isAdmin }),
      );
    }
  });

  return routes;
};

export const useRoutes = (props: PageRoutesProps): RouteProps[] => {
  return createRoutes(props);
};
