import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import {
  Blog,
  Blog as BlogComponentProps,
  Image as ImageProps,
} from '@global-website/library';

import { Image } from '../../Image';
import MESSAGES from './Messages';

export interface BlogPreviewListComponentProps {
  blog: Blog;
  type: number;
}

export const BlogList: React.FC<BlogPreviewListComponentProps> = (props) => {
  const { blog, type } = props;

  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = new Date(blog.publishDate);
  const monthName = months[date.getMonth()];

  return (
    <>
      <Link to={encodeURI(`blog/${blog.slug}`)} className={`blog-${type}`}>
        {type !== 2 && type !== 5 && (
          <Image
            className="blog-item-img"
            {...(blog.previewImage as ImageProps)}
          />
        )}

        <div className="blog-item-text-content">
          <div className="blog-item-text-date">
            {monthName} {date.getFullYear()}
          </div>
          <div className="blog-item-text-title">{blog.title}</div>
        </div>
      </Link>
    </>
  );
};

export const BlogNext: React.FC<BlogComponentProps> = (props) => {
  const item = props as BlogComponentProps;

  return (
    <Container fluid={true} className="blog-next-container">
      <Row>
        <Col>
          <h2 className="blog-next-title">{item.title}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={encodeURI(item.slug)} className="blog-detail-link">
            <span className="ml-0">
              <FormattedMessage {...MESSAGES.seeHow} />
            </span>
            <i className="icon icon-arrow-right" />
          </Link>
        </Col>
      </Row>
    </Container>
  );
};
