import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, match as Match, RouteComponentProps } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import {
  Blog,
  ReactReduxRequestDispatch,
  requestAction,
  RequestStatus,
  WithConfigProps,
} from '@global-website/library';

import {
  GlobalState,
  PublicRequestKeys,
  withConfig,
} from '../../../../Library';
import { Image } from '../../Image';
import ContentResolver from '../ContentResolver';
import { BlogNext } from './BlogComponent';
import MESSAGES from './Messages';
import { PageMeta } from '../../Pages';
import { ShareButtons } from '../../Share';
import { BlogPerson } from './BlogPerson';

export interface BlogDetailProps extends WithConfigProps {
  [PublicRequestKeys.Blog]: GlobalState[PublicRequestKeys.Blog];
  [PublicRequestKeys.Homepage]: GlobalState[PublicRequestKeys.Homepage];
  path: string;
  dispatch: ReactReduxRequestDispatch;
  routeProps: RouteComponentProps;
  isAdmin: boolean;
}

export class BlogDetailBase extends React.Component<BlogDetailProps> {
  private request() {
    const { config, dispatch, routeProps } = this.props;
    if (config) {
      const url = config.api.createApiUrl(config.api.config);
      const match = routeProps.match as Match<{ slug: string }>;
      url.pathname = `blogs/find-by-slug/${match.params.slug}`;

      dispatch(
        requestAction.load(PublicRequestKeys.Blog, {
          url: url.href,
        }),
      );
    }
  }

  public componentDidMount() {
    this.request();
  }

  public componentDidUpdate(prevProps: BlogDetailProps) {
    const { routeProps } = this.props;
    if (
      routeProps.location.pathname !== prevProps.routeProps.location.pathname
    ) {
      this.request();
    }
  }

  public componentWillUnmount() {
    const { dispatch, blog } = this.props;

    if (blog.status === RequestStatus.Loaded) {
      dispatch(requestAction.clear(PublicRequestKeys.Blog));
    }
  }

  private get getBlog(): Blog | undefined {
    const { blog } = this.props;

    return blog.result ? blog.result : undefined;
  }

  render() {
    const { path, isAdmin, homepage } = this.props;

    return (
      <article>
        {this.getBlog && (
          <>
            {homepage && homepage.result && (
              <PageMeta
                author={`${
                  (this.getBlog.author &&
                    `${this.getBlog.author.firstName} ${this.getBlog.author.lastName}`) ||
                  undefined
                }`}
                homepage={homepage.result}
                image={this.getBlog.image}
                meta={this.getBlog.meta}
                title={this.getBlog.title}
                useMicro={true}
              />
            )}
            <section className="section blog-detail">
              <header>
                <Container>
                  <Row>
                    <Col className="mb-4">
                      <Link
                        to={encodeURI(path)}
                        title="Blog"
                        className="blog-detail-link"
                      >
                        <i className="icon icon-arrow-left" />
                        <span className="ml-3">
                          <FormattedMessage {...MESSAGES.backToBlockOverview} />
                        </span>
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="9">
                      <h1 className="mb-0 mb-sm-4">{this.getBlog.title}</h1>
                    </Col>
                  </Row>
                  <Row className="align-items-center mb-md-5 mt-3 mt-md-0">
                    <Col md={6}>
                      {this.getBlog.author && (
                        <div className="blog-author mb-4 mb-md-0">
                          <Row className="align-items-center">
                            <Col xs={4} sm={3} md={2}>
                              <div className="blog-author-image">
                                {this.getBlog.author.image ? (
                                  <Image {...this.getBlog.author.image} />
                                ) : (
                                  <BlogPerson />
                                )}
                              </div>
                            </Col>
                            <Col className="p-0">
                              <p className="mb-0 small font-weight-lighter">
                                <FormattedMessage {...MESSAGES.writtenOn} />{' '}
                                <FormattedDate
                                  value={this.getBlog.updated_at}
                                  day="2-digit"
                                  month="2-digit"
                                  year="numeric"
                                />{' '}
                                <FormattedMessage {...MESSAGES.writtenBy} />{' '}
                              </p>
                              <p className="mb-0 h5">
                                {this.getBlog.author.firstName}{' '}
                                {this.getBlog.author.lastName}{' '}
                                <span className="small font-weight-lighter">
                                  - {this.getBlog.author.jobTitle}
                                </span>
                              </p>
                              <p className="small mb-0">
                                {this.getBlog.author.shortDescription}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                    <Col>
                      <ShareButtons
                        forBlog={true}
                        name={this.getBlog.title}
                        summary={this.getBlog.lead}
                        source={window.location.href}
                        url={window.location.href}
                      />
                    </Col>
                  </Row>
                </Container>
                <Container fluid={true}>
                  <Row className="blog-item-row">
                    <Image className="blog-item-img" {...this.getBlog.image} />
                  </Row>
                </Container>
              </header>
              <section className="section section-detail blog-item-content-section">
                {(this.getBlog.blogTag && this.getBlog.blogTag.length && (
                  <Container>
                    <Row className="blog-item-tag">
                      <Col md={10} className="offset-md-1">
                        {this.getBlog.blogTag.map(
                          (tag, index) =>
                            tag && (
                              <>
                                {index !== 0 ? (
                                  <span className="span-trim">|</span>
                                ) : (
                                  ''
                                )}
                                <span>{tag.name}</span>
                              </>
                            ),
                        )}
                      </Col>
                    </Row>
                  </Container>
                )) ||
                  ''}
                <div className="page-content-text-lead">
                  <Container>
                    <Row>
                      <Col md={10} className="offset-md-1">
                        <p className="lead">{this.getBlog.lead}</p>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {this.getBlog && (
                  <ContentResolver
                    isAdmin={isAdmin}
                    contents={this.getBlog.content}
                    path={path}
                  />
                )}
              </section>
            </section>

            {this.getBlog.nextBlog && <BlogNext {...this.getBlog.nextBlog} />}
          </>
        )}
      </article>
    );
  }
}

export const BlogDetail = connect((state: GlobalState) => ({
  [PublicRequestKeys.Blog]: state[PublicRequestKeys.Blog],
  [PublicRequestKeys.Homepage]: state[PublicRequestKeys.Homepage],
}))(withConfig(BlogDetailBase));

export default BlogDetail;
