import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  ContentKeys,
  JobList as JobListProps,
  JobListType,
} from '@global-website/library';

import { RecruitmentListComponent, RecruitmentRoute } from '../Recruitment';
import JobListLatest from './JobListLatest';
import JobRoute from './JobRoute';

export interface JobListComponentProps extends JobListProps {
  isAdmin: boolean;
  path: string;
  routeProps?: RouteComponentProps;
}

export const JobList: React.FunctionComponent<JobListComponentProps> = (
  props,
) => {
  const { type, __component } = props;

  switch (type) {
    case JobListType.Latest:
      if (__component === ContentKeys.RecruitmentList) {
        return <RecruitmentListComponent {...props} listALl={false} />;
      }
      return <JobListLatest {...props} />;
    case JobListType.All:
    case JobListType.Filter:
      if (__component === ContentKeys.RecruitmentList) {
        return <RecruitmentRoute {...props} />;
      }
      return <JobRoute {...props} />;
    default:
      return null;
  }
};

export default JobList;
