import { stringify } from 'qs';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import {
  ApplicationKeys,
  Homepage,
  RestMethodKeys,
  RolePermissionTypes,
  User,
} from '@global-website/library';

import { createDefaultData, Form, FormProvider } from '../../../Library';
import { usePermission } from '../../Hooks';
import { EditModal } from './EditModal';
import { homepageFormData } from './homepageFormData';

export interface HomepageSettingsProps {
  homepage: Homepage;
  user: User;
  path?: string;
  type: ApplicationKeys;
}

export const HomepageSettings: FunctionComponent<HomepageSettingsProps> = ({
  homepage,
  path,
  user,
  type,
}) => {
  const [hasPermission] = usePermission({
    id: user?.role?.id,
    methods: [RestMethodKeys.Update, RestMethodKeys.Create],
    permission: RolePermissionTypes.Application,
    type,
  });

  const query = {
    settings: ApplicationKeys.Homepage,
  };

  const pathname = `${path}?${stringify(query)}`;
  const defaultData = createDefaultData(homepageFormData);

  return (
    (hasPermission && (
      <div className="nav nav-settings">
        <Link className="btn btn-sm btn-primary" to={pathname}>
          <i className="fa fa-cogs" />
          <span className="sr-only">Settings</span>
        </Link>
        <EditModal pathname={pathname}>
          <FormProvider data={defaultData} inputConfig={homepageFormData}>
            <Form
              asForm={true}
              onChange={(data) => {
                // console.log(data);
              }}
            />
          </FormProvider>
        </EditModal>
      </div>
    )) ||
    null
  );
};

export default HomepageSettings;
