import React from 'react';
import { useSelector } from 'react-redux';

import { ApplicationKeys, Homepage } from '@global-website/library';

import { GlobalState } from '../../../Library';
import { ContentResolver } from '../Content';
import { HomepageSettings } from '../Edit';
import PageMeta from './PageMeta';

export interface HomepageResolverProps {
  homepage: Homepage;
  isAdmin: boolean;
  path?: string;
}

export const HomepageResolver: React.FC<HomepageResolverProps> = (props) => {
  const { homepage, isAdmin, path } = props;

  const user = useSelector((state: GlobalState) => state.user);

  return (
    <>
      <PageMeta
        homepage={homepage}
        title={homepage.title}
        meta={homepage.meta}
      />
      {isAdmin && user?.result && (
        <HomepageSettings
          homepage={homepage}
          path={path}
          user={user.result}
          type={ApplicationKeys.Homepage}
        />
      )}
      {homepage.content && (
        <ContentResolver
          contents={homepage.content}
          isAdmin={isAdmin}
          path={'/'}
        />
      )}
    </>
  );
};

export default HomepageResolver;
