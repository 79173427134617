import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useCookie } from 'react-use';
import { Col, Row } from 'reactstrap';

import { ConsentBase } from '@global-website/library/build';

import {
  Form,
  FormClickEvent,
  FormDataConfig,
  FormInputTypes,
  FormProvider,
  GoogleTagManager,
} from '../../../Library';
import { SubmitButton } from '../../../Library/Form/Fields';
import MESSAGES from './Messages';

export interface ConsentFormData {
  default: boolean;
  googleTag: boolean;
}

export interface CookieValue {
  accepted: boolean;
  consent: ConsentFormData;
  initialized: boolean;
  domain: string;
}

export const CookieConsent: FunctionComponent<
  ConsentBase & { host: string }
> = ({ description, googleTag, host, title }) => {
  const [value, updateCookie] = useCookie('cookie-consent');

  const [parsedValue, setParsedValue] = useState<CookieValue | undefined>(
    value && JSON.parse(value),
  );
  const intl = useIntl();

  const formData: FormDataConfig<Partial<ConsentFormData>>[] = [
    {
      editable: false,
      key: 'default',
      required: false,
      tooltip: intl.formatMessage({ ...MESSAGES.standardCookiesText }),
      title: intl.formatMessage({ ...MESSAGES.standardCookiesMandatoryText }),
      type: FormInputTypes.Checkbox,
    },
    {
      editable: true,
      key: 'googleTag',
      required: false,
      tooltip: googleTag?.description,
      title: googleTag?.title || '',
      type: FormInputTypes.Checkbox,
    },
  ];

  const updateCookieHandler = useCallback(
    (event: FormClickEvent<ConsentFormData>) => {
      if (!parsedValue) {
        return;
      }

      const data = event.submitData.data;

      const newValue: CookieValue = {
        ...parsedValue,
        accepted: true,
        consent: data,
      };
      setParsedValue(newValue);
      updateCookie(JSON.stringify(newValue));
    },
    [parsedValue, updateCookie],
  );

  useEffect(() => {
    const cookieValue = value && (JSON.parse(value) as CookieValue);
    if (!cookieValue || (cookieValue && !cookieValue.initialized)) {
      const newValue: CookieValue = {
        ...parsedValue,
        accepted: false,
        initialized: true,
        consent: {
          default: true,
          googleTag: false,
        },
        domain: host,
      };

      setParsedValue(newValue);

      updateCookie(JSON.stringify(newValue), {
        sameSite: 'strict',
        expires: new Date(new Date().setDate(new Date().getDate() + 30)),
      });
    }
  }, [value, parsedValue, setParsedValue, updateCookie, host]);

  return parsedValue && !parsedValue.accepted ? (
    <div className="cookie-consent">
      <div className="cookie-consent-inner">
        <FormProvider
          inputConfig={formData}
          data={{ googleTag: true, default: true }}
        >
          <Row>
            <Col md={12}>
              <h3>{title}</h3>
              <p>
                {description ||
                  intl.formatMessage({ ...MESSAGES.weAreUsingCookies })}
              </p>
              <Form asForm={true} />
            </Col>
            <Col className="text-right" md={12}>
              <SubmitButton
                className="ml-3 btn-green"
                onClick={updateCookieHandler}
              >
                {intl.formatMessage({ ...MESSAGES.save })}
              </SubmitButton>
            </Col>
          </Row>
        </FormProvider>
      </div>
    </div>
  ) : (
    <>
      {parsedValue && parsedValue.consent.googleTag && googleTag && (
        <GoogleTagManager id={googleTag.scriptId} />
      )}
    </>
  );
};
