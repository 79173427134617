import { defineMessages } from 'react-intl'

const MESSAGES = defineMessages({
  goTo: {
    id: 'Tab.goTo',
    defaultMessage: 'go To',
  },
});

export default MESSAGES;
