import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { apiConfig, createApiUrl } from '@global-website/library';

export const getFileExtension = (filename: string) => {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
};

export enum ImageFileExtensions {
  JPEG = 'JPEG',
  WebP = 'WebP',
  GIF = 'GIF',
  PNG = 'PNG',
  APNG = 'APNG',
  MNG = 'MNG',
  XBM = 'XBM',
  BMP = 'BMP',
  ICO = 'ICO',
}

export const AssetRenderer: FunctionComponent<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
> = (props) => {
  const api = createApiUrl(apiConfig);
  api.pathname = props.src || '';

  const fileExtension = getFileExtension(props.src || '').toUpperCase();

  let isImage = false;

  switch (fileExtension) {
    case ImageFileExtensions.JPEG:
    case ImageFileExtensions.WebP:
    case ImageFileExtensions.GIF:
    case ImageFileExtensions.PNG:
    case ImageFileExtensions.APNG:
    case ImageFileExtensions.MNG:
    case ImageFileExtensions.XBM:
    case ImageFileExtensions.BMP:
    case ImageFileExtensions.ICO:
      isImage = true;
  }
  const title = props && props.title ? props.title : props.alt;

  if (isImage) {
    return (
      <>
        <img src={api.href} alt={props.alt} />
      </>
    );
  }

  return (
    <a
      className={classNames(
        'link',
        'link-download',
        `link-download-${fileExtension}`,
      )}
      href={api.href}
      rel="noreferrer"
      title={title}
      target={fileExtension.toUpperCase() === 'PDF' ? '_blank' : '_top'}
    >
      <i className="fa fa-file" /> {title}
    </a>
  );
};
