import React, { FunctionComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { ImageBoxList as ImageBoxListModel } from '@global-website/library';

import { Image } from '../../Image';
import { BranchItemText } from '../Branch';
import { ContentResolverItemProps } from '../ContentResolver';
import ImageBoxLink from './ImageBoxLink';

export interface ImageBoxListProps extends ImageBoxListModel {}

export const ImageBoxList: FunctionComponent<
  ImageBoxListProps & ContentResolverItemProps
> = ({ contents }) => {
  if (!contents) {
    return null;
  }

  return (
    <Container fluid={true} className="p-md-0">
      <Row className="m-md-0 p-md-0">
        <Col md={12} className="p-md-0 m-md-0">
          {contents.map((content, index) => {
            return (
              <div
                key={`image_box_item${content.id}_${index}`}
                className="branches-item image-box-item"
              >
                <Row className="align-items-end p-md-0 m-md-0">
                  {!(index % 2) ? (
                    <>
                      <Col md={6} className="p-md-0 m-md-0">
                        {content.links && content.links[0] ? (
                          <ImageBoxLink {...content.links[0]}>
                            <Image
                              {...content.image}
                              className="branches-item-img"
                            />
                          </ImageBoxLink>
                        ) : (
                          <Image
                            {...content.image}
                            className="branches-item-img"
                          />
                        )}
                      </Col>
                      <Col md={6} className="p-md-0 m-md-0">
                        <div className="branches-item-text">
                          <BranchItemText
                            lead={content.lead}
                            links={content.links}
                            position="right"
                            title={content.title}
                            slug={content.slug}
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={6} className="p-md-0 m-md-0 order-md-1 order-2">
                        <div className="branches-item-text">
                          <BranchItemText
                            lead={content.lead}
                            links={content.links}
                            position="left"
                            title={content.title}
                            slug={content.slug}
                          />
                        </div>
                      </Col>
                      <Col md={6} className="p-md-0 m-md-0 order-md-2 order-1">
                        {content.links && content.links[0] ? (
                          <ImageBoxLink {...content.links[0]}>
                            <Image
                              {...content.image}
                              className="branches-item-img"
                            />
                          </ImageBoxLink>
                        ) : (
                          <Image
                            {...content.image}
                            className="branches-item-img"
                          />
                        )}
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};
