import React, { FunctionComponent, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Recruitment, RecruitmentList } from '@global-website/library';

import RecruitmentListLatestItem from './RecruitmentListLatestItem';

export const RecruitmentListLatestContent: FunctionComponent<{
  list: RecruitmentList;
  recruitments: Recruitment[];
  listAll: boolean;
}> = ({ list, listAll, recruitments }) => {
  const { lead, title, homepage } = list;
  const [active, setActive] = useState<number | undefined>(undefined);

  const handleToggle = (index: number, state: boolean) => {
    setActive(state ? index : undefined);
  };

  return (
    <section className="section section-job section-job-latest">
      <Container fluid={true}>
        <Row>
          <Col md={6} className="offset-md-3 text-center mb-5">
            <h3 className="h2 mb-0">{title}</h3>
            {lead && <p className="lead">{lead}</p>}
          </Col>
        </Row>
        <div className="job job-latest">
          {homepage && !listAll && (
            <div className="job-latest-item job-latest-title">
              {homepage.title}
            </div>
          )}
          {recruitments &&
            recruitments.length &&
            recruitments.map((recruitment, index) => {
              return (
                <RecruitmentListLatestItem
                  contact={list.contact || undefined}
                  homepage={list.homepage || undefined}
                  index={index}
                  isOpen={active === index}
                  key={`recruitment_list_latest_item_${recruitment.id}_${index}`}
                  page={list.page || undefined}
                  recruitment={recruitment}
                  toggle={handleToggle}
                  portalLink={list.portalLink}
                />
              );
            })}
        </div>
      </Container>
    </section>
  );
};

export default RecruitmentListLatestContent;
