import React, { FunctionComponent } from 'react';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { parse, stringify } from 'qs';
import type { Link as LinkProps } from 'mdast';
import validator from 'validator';

export interface LinkRendererProps {
  children?: ReactNode[];
  href: string;
  node: LinkProps;
}

/**
 * Try to get a "target" query string parameter
 * to set as a
 * @param url
 */
const linkRenderUrl = (
  url: URL,
): { href: string; search: Object; target?: string } => {
  const search =
    url.search.indexOf('?') === 0 ? url.search.substring(1) : url.search;
  const query = parse(search);
  const target = (query.target as string) || undefined;

  if ('target' in query) {
    delete query.target;
  }

  return {
    href: url.origin + url.pathname,
    search: stringify(query),
    target,
  };
};

type DetailedAnchorAttr = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

/**
 * internal link /a/b/c                 -> <Link> from react-router-dom
 * external link http[s]?://example.com -> <a>
 * @param props
 */
export const LinkRenderer: FunctionComponent<DetailedAnchorAttr> = (props) => {
  const {
    children,
    href,
    title
  } = props;
  const isUrl = validator.isURL(href || '');

  if (!isUrl) {
    return (
      <Link to={href || ''} title={title}>
        {children || title || href}
      </Link>
    );
  }

  const url = linkRenderUrl(new URL(href || ''));
  const link = `${url.href}${
    Object.keys(url.search).length > 0 ? '?' + url.search : ''
  }`;
  const target = url.target
    ? {
        target: url.target,
        rel: 'noopener',
      }
    : {};

  return (
    <a href={link} title={title || link} {...target}>
      {children || title || link}
    </a>
  );
};
