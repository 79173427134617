export enum PublicRequestKeys {
  Alerts = 'alerts',
  Authentication = 'authentication',
  Blog = 'blog',
  Blogs = 'blogs',
  BlogPreview = 'blogPreview',
  Competences = 'competences',
  Downloads = 'downloads',
  Branch = 'branch',
  Branches = 'branches',
  Homepage = 'homepage',
  Homepages = 'homepages',
  Job = 'job',
  Jobs = 'jobs',
  JobsLatest = 'jobsLatest',
  Locals = 'locals',
  Page = 'page',
  Pages = 'pages',
  Recruitment = 'recruitment',
  Recruitments = 'recruitments',
  Router = 'router',
  Service = 'service',
  Services = 'services',
  ServicesFilter = 'servicesFilter',
}

export enum PrivateRequestKeys {
  Role = 'role',
  Roles = 'roles',
  Permission = 'permission',
  Permissions = 'permissions',
  User = 'user',
}
