import assert from 'react-share/lib/utils/assert';
import objectToGetParams from 'react-share/lib/utils/objectToGetParams';
import createShareButton from 'react-share/lib/hocs/createShareButton';

type Options = {};

// eslint-disable-next-line no-empty-pattern
function xingLink(url: string, {}: Options) {
  assert(url, 'xing.url');

  return 'https://www.xing.com/spi/shares/new' + objectToGetParams({ url });
}

export const XingShareButton = createShareButton<Options>(
  'xing',
  xingLink,
  // eslint-disable-next-line no-empty-pattern
  ({}) => ({}),
  {
    windowWidth: 750,
    windowHeight: 600,
  },
);
