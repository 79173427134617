import { defineMessages } from 'react-intl'

const MESSAGES = defineMessages({
  language: {
    id: 'Footer.language',
    defaultMessage: 'Language',
  },
});

export default MESSAGES;
