import { Homepage, Meta } from '@global-website/library/build';

import {
  FormDataConfig,
  FormInputTypes,
  FormValidationTypes,
} from '../../../Library';

export type HomepageFormData = FormDataConfig<
  Pick<Homepage, 'active' | 'title' | 'meta' | 'menuTitle'>
>[];

export type MetaFormData = FormDataConfig<Pick<Meta, 'name' | 'content'>>[];

const metaFormData: MetaFormData = [
  {
    editable: true,
    key: 'name',
    required: true,
    title: 'Meta title',
    type: FormInputTypes.Select,
    selectValues: ['description', 'keywords'],
  },
  {
    editable: true,
    key: 'content',
    required: true,
    type: FormInputTypes.String,
    title: 'Meta Content',
    validate: FormValidationTypes.Length,
  },
];

export const homepageFormData: HomepageFormData = [
  {
    editable: true,
    key: 'active',
    required: true,
    title: 'Is active',
    type: FormInputTypes.Checkbox,
  },
  {
    editable: true,
    key: 'menuTitle',
    required: true,
    title: 'Navigation title',
    type: FormInputTypes.String,
    validate: FormValidationTypes.Length,
  },
  {
    editable: true,
    key: 'title',
    required: true,
    title: 'Homepage title',
    type: FormInputTypes.String,
    validate: FormValidationTypes.Length,
  },
  {
    editable: true,
    key: 'meta',
    required: true,
    title: 'Meta',
    type: FormInputTypes.MultipleGroup,
    group: metaFormData,
    validate: FormValidationTypes.Group,
  },
];
