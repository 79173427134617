import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
  backTo: {
    id: 'Job.backTo',
    defaultMessage: 'Back to',
  },
  overview: {
    id: 'Job.overview',
    defaultMessage: 'Overview',
  },
  applyNow: {
    id: 'Job.applyNow',
    defaultMessage: 'Apply now',
  },
  position: {
    id: 'Job.position',
    defaultMessage: 'Position',
  },
  location: {
    id: 'Job.location',
    defaultMessage: 'Location',
  },
  start: {
    id: 'Job.start',
    defaultMessage: 'Start',
  },
  contactPerson: {
    id: 'Job.contactPerson',
    defaultMessage: 'Contact Person',
  },
  eMail: {
    id: 'Job.eMail',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'Job.phone',
    defaultMessage: 'Phone',
  },
  writeTo: {
    id: 'Job.writeTo',
    defaultMessage: 'Write to',
  },
  fromNowOn: {
    id: 'Job.fromNowOn',
    defaultMessage: 'from now on',
  },
  readMore: {
    id: 'Job.readMore',
    defaultMessage: 'Read more',
  },
});

export default MESSAGES;
