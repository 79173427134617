import { defineMessages } from 'react-intl'

const MESSAGES = defineMessages({
  backTo: {
    id: 'Recruitment.backTo',
    defaultMessage: 'Back to',
  },
  overview: {
    id: 'Recruitment.overview',
    defaultMessage: 'Overview',
  },
  applyNow: {
    id: 'Recruitment.applyNow',
    defaultMessage: 'Apply now',
  },
  position: {
    id: 'Recruitment.position',
    defaultMessage: 'Position',
  },
  location: {
    id: 'Recruitment.location',
    defaultMessage: 'Location',
  },
  start: {
    id: 'Recruitment.start',
    defaultMessage: 'Start',
  },
  contactPerson: {
    id: 'Recruitment.contactPerson',
    defaultMessage: 'Contact Person',
  },
  eMail: {
    id: 'Recruitment.eMail',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'Recruitment.phone',
    defaultMessage: 'Phone',
  },
  writeTo: {
    id: 'Recruitment.writeTo',
    defaultMessage: 'Write to',
  },
  fromNowOn: {
    id: 'Recruitment.fromNowOn',
    defaultMessage: 'from now on',
  },
  readMore: {
    id: 'Recruitment.readMore',
    defaultMessage: 'Read more',
  },
});

export default MESSAGES;
