import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LinkedinShareButton, EmailShareButton } from 'react-share';
import { XingShareButton } from './XingShareButton';

import MESSAGES from './Messages';
import { Col, Nav, NavItem, Row } from 'reactstrap';

interface ShareButtonsProps {
  forBlog?: boolean;
  name: string;
  source: string;
  summary: string;
  url: string;
}

export const ShareButtons: FunctionComponent<ShareButtonsProps> = ({
  forBlog,
  name,
  source,
  summary,
  url,
}) => {
  const intl = useIntl();
  if (!forBlog) {
    return (
      <>
        <span className="mr-2">
          <FormattedMessage {...MESSAGES.shareOn} />
        </span>
        <XingShareButton
          url={url}
          className="btn btn-secondary p-2 mr-1"
          resetButtonStyle={false}
          title={`${intl.formatMessage({ ...MESSAGES.shareOn })} Xing`}
        >
          <i className="icon-xing" />
          <span className="sr-only">
            {`${intl.formatMessage({ ...MESSAGES.shareOn })} Xing`}
          </span>
        </XingShareButton>
        <LinkedinShareButton
          name={name}
          source={source}
          summary={summary}
          url={url}
          className="btn btn-secondary p-2 mr-1"
          resetButtonStyle={false}
          title={`${intl.formatMessage({ ...MESSAGES.shareOn })} LinkedIn`}
        >
          <i className="icon-linkedin" />
          <span className="sr-only">
            {`${intl.formatMessage({ ...MESSAGES.shareOn })} LinkedIn`}
          </span>
        </LinkedinShareButton>
        <EmailShareButton
          url={url}
          className="btn btn-secondary p-2 mr-1"
          resetButtonStyle={false}
          title={`${intl.formatMessage({ ...MESSAGES.shareOn })} mail`}
        >
          <i className="fa fa-envelope" />
          <span className="sr-only">
            {`${intl.formatMessage({ ...MESSAGES.shareOn })} mail`}
          </span>
        </EmailShareButton>
      </>
    );
  }

  return (
    <Row>
      <Col>
        <div className="d-flex align-items-center justify-content-end">
          <span className="mr-3">
            <FormattedMessage {...MESSAGES.shareOn} />
          </span>
          <Nav className="nav-sub nav-social">
            <NavItem>
              <LinkedinShareButton
                name={name}
                source={source}
                summary={summary}
                url={url}
                className="btn btn-lg p-2 btn-ghost btn-blue mr-2"
                resetButtonStyle={false}
                title={`${intl.formatMessage({
                  ...MESSAGES.shareOn,
                })} LinkedIn`}
              >
                <i className="icon-linkedin" />
                <span className="sr-only">
                  {`${intl.formatMessage({ ...MESSAGES.shareOn })} LinkedIn`}
                </span>
              </LinkedinShareButton>
              <XingShareButton
                url={url}
                className="btn btn-lg p-2 btn-ghost btn-blue mr-2"
                resetButtonStyle={false}
                title={`${intl.formatMessage({ ...MESSAGES.shareOn })} Xing`}
              >
                <i className={`icon-xing`} />
                <span className="sr-only">
                  {`${intl.formatMessage({ ...MESSAGES.shareOn })} Xing`}
                </span>
              </XingShareButton>
              <EmailShareButton
                url={url}
                className="btn btn-lg p-2 btn-ghost btn-blue mr-2"
                resetButtonStyle={false}
                title={`${intl.formatMessage({ ...MESSAGES.shareOn })} mail`}
              >
                <i className="icon-mail" />
                <span className="sr-only">
                  {`${intl.formatMessage({ ...MESSAGES.shareOn })} mail`}
                </span>
              </EmailShareButton>
            </NavItem>
          </Nav>
        </div>
      </Col>
    </Row>
  );
};
