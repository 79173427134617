import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';

import { Job } from '@global-website/library';

import MESSAGES from './Messages';

export const JobInformation: React.FunctionComponent<Job> = ({
  contact,
  name,
  location,
  startDate,
}) => {
  const currentTime = new Date().getTime();
  const startTime = startDate ? new Date(startDate).getTime() : currentTime;
  const inPast = startTime <= currentTime;
  const email = (contact && contact.email) || 'recruiting@globalct.com';

  const intl = useIntl();

  return (
    <div className="section-detail-job-info mt-3">
      <Row>
        <Col md={6}>
          <dl>
            <dt>
              <FormattedMessage {...MESSAGES.position} />:
            </dt>
            <dd>{name}</dd>
            {location && (
              <>
                <dt>
                  <FormattedMessage {...MESSAGES.location} />:
                </dt>
                <dd>{location}</dd>
              </>
            )}
            {startTime && (
              <>
                <dt>
                  <FormattedMessage {...MESSAGES.start} />:
                </dt>
                <dd>
                  {inPast ? (
                    <FormattedMessage {...MESSAGES.fromNowOn} />
                  ) : (
                    startDate
                  )}
                </dd>
              </>
            )}
          </dl>
        </Col>
        {contact && (
          <Col md={4} className="offset-md-2">
            <dl>
              <dt>
                <FormattedMessage {...MESSAGES.contactPerson} />:
              </dt>
              <dd>
                {contact.firstName} {contact.lastName}
              </dd>
              <dt>
                <FormattedMessage {...MESSAGES.eMail} />
              </dt>
              <dd>
                <a
                  href={`mailto:${email}`}
                  title={`${intl.formatMessage({
                    ...MESSAGES.writeTo,
                  })} ${email}`}
                >
                  {email}
                </a>
              </dd>
              <dt>
                <FormattedMessage {...MESSAGES.phone} />:
              </dt>
              <dd>
                <a
                  href={`tel:${contact.phone}`}
                  title={`${intl.formatMessage({ ...MESSAGES.phone })} ${
                    contact.phone
                  }`}
                >
                  {contact.phone}
                </a>
              </dd>
            </dl>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default JobInformation;
