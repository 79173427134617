import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Collapse, Row } from 'reactstrap';

import { Author, Homepage, Page, Recruitment } from '@global-website/library';

import MESSAGES from './Messages';
import RecruitmentInformation from './RecruitmentInformation';
import { MarkdownRenderer } from '../../../../Library';

interface RecruitmentListLatestItemProps {
  contact?: Author;
  homepage?: Homepage;
  index: number;
  isOpen: boolean;
  page?: Page;
  portalLink?: string;
  recruitment: Recruitment;
  toggle?: (index: number, state: boolean) => void;
}

export const RecruitmentListLatestItem: FunctionComponent<
  RecruitmentListLatestItemProps
> = (props) => {
  const { recruitment, contact } = props;

  const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);

  const toggle = () => {
    setIsOpen(!isOpen);
    if (props.toggle) {
      props.toggle(props.index, !isOpen);
    }
  };

  useEffect(() => {
    if (props.isOpen !== isOpen) {
      setIsOpen(props.isOpen);
    }
  }, [props.isOpen, isOpen]);

  return (
    <div className="job-latest-item">
      <div
        className={classNames('job-latest-btn', {
          active: isOpen,
        })}
        onClick={toggle}
        tabIndex={1}
      >
        {recruitment.name} <i className="icon icon-arrow-right" />
      </div>
      <Collapse isOpen={isOpen}>
        <div className="job-latest-content">
          <Row>
            <Col md={8} sm={12}>
              {recruitment.jobDescriptions &&
                recruitment.jobDescriptions.map((description, index) => {
                  return (
                    <div
                      className={classNames('job-latest-description', 'mb-5')}
                      key={`job_latest_description_${recruitment.id}_${index}`}
                    >
                      <h4 className="mb-2">{description.name}</h4>
                      {description.value && (
                        <MarkdownRenderer content={description.value} />
                      )}
                    </div>
                  );
                })}
              <RecruitmentInformation {...recruitment} contact={contact} />
              <a
                className="btn btn-blue btn-ghost"
                href={`${
                  props.portalLink || 'https://globalct.jobs.personio.de/job'
                }/${recruitment.externalId}`}
                target="_blank"
                title="Jetzt Bewerben"
                rel="noreferrer"
              >
                <FormattedMessage {...MESSAGES.applyNow} />
              </a>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

export default RecruitmentListLatestItem;
