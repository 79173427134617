import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
  backTo: {
    id: 'Services.backTo',
    defaultMessage: 'Back to',
  },
  overview: {
    id: 'Services.overview',
    defaultMessage: 'Overview',
  },
});

export default MESSAGES;
