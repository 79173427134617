import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import {
  ImageContentComponentKeys,
  ImageContentLink,
} from '@global-website/library';

export const ImageBoxLink: FunctionComponent<
  ImageContentLink & { children?: ReactNode }
> = (link) => {
  switch (link.__component) {
    case ImageContentComponentKeys.BlogLink:
      return (
        (link.page && link.blog && (
          <Link
            className={(!link.children && 'btn pl-0') || 'd-block'}
            to={encodeURI(`/${link.page.slug}/${link.blog.slug}`)}
            title={link.title}
          >
            {link.children || (
              <>
                {link.text} <i className="icon icon-arrow-right" />
              </>
            )}
          </Link>
        )) ||
        null
      );
    case ImageContentComponentKeys.BranchLink:
      return (
        (link.page && link.branch && (
          <Link
            className={(!link.children && 'btn pl-0') || 'd-block'}
            to={encodeURI(`/${link.page.slug}/${link.branch.slug}`)}
            title={link.title}
          >
            {link.children || (
              <>
                {link.text} <i className="icon icon-arrow-right" />
              </>
            )}
          </Link>
        )) ||
        null
      );
    case ImageContentComponentKeys.ExternalLink:
      return (
        <a
          className={(!link.children && 'btn pl-0') || 'd-block'}
          href={link.href}
          rel="noreferrer"
          title={link.title}
          target="_blank"
        >
          {link.children || (
            <>
              {link.text} <i className="icon icon-arrow-right" />
            </>
          )}
        </a>
      );
    case ImageContentComponentKeys.JobLink:
      return (
        (link.page && link.job && (
          <Link
            className={(!link.children && 'btn pl-0') || 'd-block'}
            to={encodeURI(`/${link.page.slug}/${link.job.slug}`)}
            title={link.title}
          >
            {link.children || (
              <>
                {link.text} <i className="icon icon-arrow-right" />
              </>
            )}
          </Link>
        )) ||
        null
      );
    case ImageContentComponentKeys.PageLink:
      const parentPageLink = link.parentPage ? `/${link.parentPage.slug}` : '';
      return (
        (link.page && (
          <Link
            className={(!link.children && 'btn pl-0') || 'd-block'}
            to={encodeURI(`${parentPageLink}/${link.page.slug}`)}
            title={link.title}
          >
            {link.children || (
              <>
                {link.text} <i className="icon icon-arrow-right" />
              </>
            )}
          </Link>
        )) ||
        null
      );
    case ImageContentComponentKeys.ServiceLink:
      return (
        (link.page && link.service && (
          <Link
            className={(!link.children && 'btn pl-0') || 'd-block'}
            to={encodeURI(`/${link.page.slug}/${link.service.slug}`)}
            title={link.title}
          >
            {link.children || (
              <>
                {link.text} <i className="icon icon-arrow-right" />
              </>
            )}
          </Link>
        )) ||
        null
      );
    default:
      return null;
  }
};

export default ImageBoxLink;
