import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { Branch, ImageContentLink, Page } from '@global-website/library';

import { Image } from '../../Image';
import { ImageBoxLink } from '../ImageBox';
import MESSAGES from './Messages';

export interface BranchItemProps extends Branch {
  index: number;
  page?: Page | null;
}

export interface BranchItemTextProps {
  position: 'left' | 'right';
  lead?: string;
  title: string;
  slug: string;
  previewLead?: string;
  page?: Page | null;
  links?: ImageContentLink[];
}

export const BranchItemText: React.FC<BranchItemTextProps> = ({
  lead,
  links,
  page,
  position,
  previewLead,
  title,
  slug,
}) => (
  <div className={`branches-item-text-${position}`}>
    <h3 className="mb-1">{title}</h3>
    {lead && <p className="lead">{previewLead || lead}</p>}
    {links && links.length ? (
      links.map((link, index) => {
        return (
          <ImageBoxLink key={`image_box_link_${link.id}_${index}`} {...link} />
        );
      })
    ) : (
      <>
        {page && (
          <Link className="btn pl-0" to={encodeURI(`/${page.slug}/${slug}`)}>
            <FormattedMessage {...MESSAGES.readMore} />{' '}
            <i className="icon icon-arrow-right" />
          </Link>
        )}
      </>
    )}
  </div>
);

export const BranchItem: React.FC<BranchItemProps> = (props) => {
  const { index } = props;

  return (
    <div className="branches-item">
      <Row className="align-items-end p-md-0 m-md-0">
        {!(index % 2) ? (
          <>
            <Col md={6} className="p-md-0 m-md-0">
              <Image {...props.previewImage} className="branches-item-img" />
            </Col>
            <Col md={6} className="p-md-0 m-md-0">
              <div className="branches-item-text">
                <BranchItemText {...props} position="right" />
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col md={6} className="p-md-0 m-md-0 order-md-1 order-2">
              <div className="branches-item-text">
                <BranchItemText {...props} position="left" />
              </div>
            </Col>
            <Col md={6} className="p-md-0 m-md-0 order-md-2 order-1">
              <Image {...props.previewImage} className="branches-item-img" />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default BranchItem;
