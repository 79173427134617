import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';

import { Contact } from '@global-website/library/build';

import shadeRight from '../../../Assets/Images/gct_circle-shape_right.svg';
import { GlobalState, PublicRequestKeys } from '../../../Library';
import { Image } from '../Image';
import ContactBoxItem from './ContactBoxItem';

interface ContactBoxProps {
  [PublicRequestKeys.ServicesFilter]: GlobalState[PublicRequestKeys.ServicesFilter];
  [PublicRequestKeys.Jobs]: GlobalState[PublicRequestKeys.Jobs];
  [PublicRequestKeys.Page]: GlobalState[PublicRequestKeys.Page];
  [PublicRequestKeys.Router]: GlobalState[PublicRequestKeys.Router];
  contact: Contact;
}

export const ContactBoxBase: FC<ContactBoxProps> = (props) => {
  const { servicesFilter, page, router, jobs } = props;

  const servîceContact =
    servicesFilter.result &&
    servicesFilter.result[0] &&
    servicesFilter.result[0].contact;
  const jobContact = jobs.result && jobs.result[0] && jobs.result[0].contactBox;
  const pageContact = page.result && page.result.contact;
  const homepageContact =
    router.location.pathname === '/' ? props.contact : null;
  const contact =
    servîceContact || pageContact || homepageContact || jobContact || null; // props.contact;

  return (
    contact &&
    ((router.location.pathname !== '/404' && (
      <section className="section section-contact">
        <div className="contact">
          <Container>
            <Row>
              <Col className="text-center mb-5">
                <h3 className="h2">{contact.title}</h3>
                {contact.lead && <p className="lead">{contact.lead}</p>}
              </Col>
            </Row>
          </Container>
          {(contact.authors && contact.authors.length > 1 && (
            <Container fluid={true} className="p-0 ">
              <Row className="align-items-end p-0 m-0">
                {contact.authors.map((contactAuthor, index) => {
                  return (
                    <React.Fragment
                      key={`contact_box_author_${contactAuthor.id}_${index}`}
                    >
                      <Col md={6} className="m-0 p-1">
                        <ContactBoxItem {...contactAuthor}></ContactBoxItem>
                      </Col>
                    </React.Fragment>
                  );
                })}
              </Row>
            </Container>
          )) ||
            (contact.authors &&
              contact.authors.length === 1 &&
              contact.authors.map((contactAuthor, index) => {
                return (
                  <Container
                    key={`contact_box_author_${contactAuthor.id}_${index}`}
                    fluid={true}
                    className="p-0 contact-container"
                  >
                    <Row className="align-items-end p-0 m-0 container-item">
                      <Col md={6} className="m-0 p-0">
                        {contactAuthor.author.image && (
                          <Image
                            className="contact-img"
                            {...contactAuthor.author.image}
                          />
                        )}
                      </Col>
                      <Col
                        md={4}
                        className="offset-md-1 mr-0 p-0 container-item container-data"
                      >
                        <div className="contact-text">
                          <h3>
                            {contactAuthor.author.firstName}{' '}
                            {contactAuthor.author.lastName}
                            {contactAuthor.author.jobTitle && (
                              <small>{contactAuthor.author.jobTitle}</small>
                            )}
                          </h3>
                          <p className="mb-5">
                            {contactAuthor.author.shortDescription}
                          </p>
                          <p className="mb-4 contact-data">
                            <a href={`tel:${contactAuthor.author.phone}`}>
                              <i className="icon-phone" />
                              {contactAuthor.author.phone}
                            </a>{' '}
                            <br />
                            <a href={`mailto:${contact.email}`}>
                              <i className="icon-mail" /> {contact.email}
                            </a>
                          </p>
                        </div>
                      </Col>
                    </Row>
                    {contact.isFluid && (
                      <div className="container-item container-image">
                        <img
                          className="img-fluid"
                          src={shadeRight}
                          alt=""
                          width={959}
                          height={608}
                        />
                      </div>
                    )}
                  </Container>
                );
              }))}
        </div>
      </section>
    )) ||
      null)
  );
};

export const ContactBox = connect((state: GlobalState) => ({
  [PublicRequestKeys.ServicesFilter]: state[PublicRequestKeys.ServicesFilter],
  [PublicRequestKeys.Jobs]: state[PublicRequestKeys.Jobs],
  [PublicRequestKeys.Page]: state[PublicRequestKeys.Page],
  [PublicRequestKeys.Router]: state[PublicRequestKeys.Router],
}))(ContactBoxBase);

export default ContactBox;
