import classNames from 'classnames';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Teaser as TeaserProps } from '@global-website/library';

import shadeRight from '../../../../Assets/Images/gct_circle-shape_right.svg';
import { ContentResolverItemDefaultProps } from '../ContentResolver';

export const Teaser: React.FC<
  TeaserProps & ContentResolverItemDefaultProps
> = ({ lead, title, isFluid }) => {
  return (
    <section
      className="section section-teaser"
      style={isFluid ? { backgroundImage: `url(${shadeRight})` } : undefined}
    >
      <div className="teaser">
        <Container fluid={true}>
          <Row className={classNames({ 'align-items-end': isFluid })}>
            <Col
              md={6}
              className={classNames({ 'offset-md-3 text-center': !isFluid })}
            >
              <h2>{title}</h2>
              <p className="lead">{lead}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Teaser;
