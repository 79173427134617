import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import {
  Link,
  match as Match,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import {
  Job,
  JobList,
  ReactReduxRequestDispatch,
  requestAction,
  RequestStatus,
  WithConfigProps,
} from '@global-website/library';

import {
  GlobalState,
  PublicRequestKeys,
  withConfig,
} from '../../../../Library';
import ContentResolver from '../ContentResolver';
import { JumbotronComponent } from '../Jumbotron';
import JobInformation from './JobInformation';
import MESSAGES from './Messages';

export interface JobDetailProps extends WithConfigProps {
  [PublicRequestKeys.Job]: GlobalState[PublicRequestKeys.Job];
  [PublicRequestKeys.Pages]: GlobalState[PublicRequestKeys.Pages];
  dispatch: ReactReduxRequestDispatch;
  isAdmin: boolean;
  jobList: JobList;
  path: string;
  routeProps: RouteComponentProps;
  intl: IntlShape;
}

export class JobDetailBase extends React.Component<JobDetailProps> {
  private request() {
    const { config, dispatch, routeProps } = this.props;

    const pageId = this.getCurrentId();

    if (config) {
      const url = config.api.createApiUrl(config.api.config);
      const match = routeProps.match as Match<{ title: string }>;
      url.pathname = 'job/find-by-title/' + match.params.title;
      url.search = (pageId && '?page.id=' + pageId) || '';

      dispatch(
        requestAction.load(PublicRequestKeys.Job, {
          url: url.href,
        }),
      );
    }
  }

  public componentDidMount() {
    this.request();
  }

  public componentDidUpdate(prevProps: JobDetailProps) {
    const { routeProps } = this.props;

    if (
      routeProps.location.pathname !== prevProps.routeProps.location.pathname
    ) {
      this.request();
    }
  }

  private get job(): Job | undefined {
    const { job } = this.props;

    return job.result ? job.result : undefined;
  }

  private getCurrentId() {
    const { pages: data, path } = this.props;

    const pages = data && data.result;
    let currentPath = path.replaceAll('/', '');

    if (!pages || (pages && !pages.length)) {
      return;
    }

    const page = pages.find((item) => item.slug === currentPath);

    return (page && page.id) || undefined;
  }

  render() {
    const { isAdmin, path } = this.props;

    return (
      <>
        {this.job ? (
          <>
            <JumbotronComponent {...this.job.header} />
            <section className="section section-detail section-detail-job">
              <div className="page-content-text-lead">
                <Container>
                  <Row>
                    <Col md={10} className="offset-md-1">
                      <p className="lead">{this.job.lead}</p>
                    </Col>
                  </Row>
                </Container>
              </div>
              {this.job && (
                <ContentResolver
                  contents={this.job.content as any}
                  isAdmin={isAdmin}
                  path={path}
                />
              )}
              <div className="page-content-text page-content-text-job">
                <Container>
                  <Row>
                    <Col md={10} className="offset-md-1 mt-3">
                      <JobInformation {...this.job} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} className="offset-md-1 mt-3">
                      <a
                        className="btn btn-green btn-sized"
                        href={`mailto: ${
                          (this.job.contact && this.job.contact.email) ||
                          'recruiting@globalct.com'
                        }`}
                        title={this.props.intl.formatMessage({
                          ...MESSAGES.applyNow,
                        })}
                      >
                        <FormattedMessage {...MESSAGES.applyNow} />
                      </a>
                    </Col>
                  </Row>
                </Container>
              </div>
            </section>
            {this.job?.page && (
              <section className="section section-detail-footer">
                <Container fluid={true}>
                  <Row>
                    <Col>
                      <Link
                        to={encodeURI(`/${this.job.page.slug}`)}
                        title={this.job.page.name || this.job.page.title}
                      >
                        <i className="icon icon-arrow-left" />
                        <span className="ml-3">
                          <FormattedMessage {...MESSAGES.backTo} />{' '}
                          {this.job.page.name || this.job.page.title}{' '}
                          <FormattedMessage {...MESSAGES.overview} />
                        </span>
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          </>
        ) : (
          <>
            {(this.props.job.status === RequestStatus.Loaded ||
              this.props.job.status === RequestStatus.Error) && (
              <Redirect to="/404" />
            )}
          </>
        )}
      </>
    );
  }
}

export const JobDetail = connect((state: GlobalState) => ({
  [PublicRequestKeys.Job]: state[PublicRequestKeys.Job],
  [PublicRequestKeys.Pages]: state[PublicRequestKeys.Pages],
}))(withConfig(injectIntl<'intl', JobDetailProps>(JobDetailBase)));

export default JobDetail;
