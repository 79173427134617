import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import {
  BlogPreviewList,
  NumberOfBlog,
  NumberOfBlogNr,
  ReactReduxRequestDispatch,
  requestAction,
  RequestStatus,
  WithConfigProps,
} from '@global-website/library';

import {
  GlobalState,
  PublicRequestKeys,
  withConfig,
} from '../../../../Library';
import { BlogList } from './BlogComponent';

type BlogPreviewListType = BlogPreviewList & WithConfigProps;

export interface BlogPreviewListProps extends BlogPreviewListType {
  [PublicRequestKeys.BlogPreview]: GlobalState[PublicRequestKeys.BlogPreview];
  dispatch: ReactReduxRequestDispatch;
  path: string;
}

export class BlogPreviewListComponentBase extends React.Component<BlogPreviewListProps> {
  componentDidMount() {
    const { config, dispatch, blogPreview, blogList } = this.props;
    let numberOfBlog = NumberOfBlog.all;
    if (blogList && blogList.NumberOfBlog) {
      numberOfBlog = blogList.NumberOfBlog;
    }
    let nrBlogToDisplay = -1;
    if (numberOfBlog === NumberOfBlog.three) {
      nrBlogToDisplay = NumberOfBlogNr.three;
    } else if (numberOfBlog === NumberOfBlog.five) {
      nrBlogToDisplay = NumberOfBlogNr.five;
    } else if (numberOfBlog === NumberOfBlog.eight) {
      nrBlogToDisplay = NumberOfBlogNr.eight;
    } else if (numberOfBlog === NumberOfBlog.nine) {
      nrBlogToDisplay = NumberOfBlogNr.nine;
    }

    if (blogPreview.status === RequestStatus.Initial && config) {
      const url = config.api.createApiUrl(config.api.config);
      url.pathname = 'blogs';
      url.search = `?_limit=${nrBlogToDisplay}&_sort=publishDate:DESC`;
      dispatch(
        requestAction.load(PublicRequestKeys.BlogPreview, {
          url: url.href,
        }),
      );
    }
  }

  componentWillUnmount() {
    const { dispatch, blogPreview } = this.props;

    if (blogPreview.status === RequestStatus.Loaded) {
      dispatch(requestAction.clear(PublicRequestKeys.BlogPreview));
    }
  }

  render() {
    const { blogPreview, title, lead } = this.props;
    const result = blogPreview.result;

    return (
      <>
        {blogPreview.status === RequestStatus.Loaded && result && (
          <section className="blog-preview-section pb-0">
            <Container fluid={true}>
              <Row>
                <Col sm={6} className="offset-sm-3 text-center">
                  <h2 className="mb-3">{title}</h2>
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="offset-sm-3 text-center">
                  <h4 className="mb-1 mb-section">{lead}</h4>
                </Col>
              </Row>
            </Container>
            <Container fluid={true} className="p-md-0 blog-container">
              {result.map((item, index) => {
                const type = index % 9;
                return <BlogList blog={item} type={type} />;
              })}
            </Container>
            <Container fluid={true} className="blog-button-container">
              <Row>
                <Col
                  sm={6}
                  lg={4}
                  className="offset-sm-3 offset-lg-4 text-center"
                >
                  <Link className="btn btn-green btn-sized" to={`/Blog`}>
                    {'Blog-Übersicht'}
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </>
    );
  }
}

export const BlogPreviewListComponent = connect((state: GlobalState) => ({
  [PublicRequestKeys.BlogPreview]: state[PublicRequestKeys.BlogPreview],
}))(withConfig(BlogPreviewListComponentBase));

export default BlogPreviewListComponent;
