import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { BenefitList as BenefitsListProps } from '@global-website/library';

export const BenefitsList: React.FunctionComponent<BenefitsListProps> = (
  props,
) => {
  const { items, lead, title } = props;

  return (
    <section className="section section-benefit-list">
      <Container fluid={true}>
        <Row>
          <Col className="text-center">
            <h3 className="h2 mb-0">{title}</h3>
            {lead && <p className="lead">{lead}</p>}
          </Col>
        </Row>
        {items && items.length > 0 && (
          <div className="benefit-list">
            <Row>
              {items.map(
                (item, index) =>
                  (item && (
                    <Col
                      key={`benefit_list_col_${item.id}_${index}`}
                      md={4}
                      xs={6}
                    >
                      <div className="benefit-list-item">
                        <i className={`icon icon-${item.benefit?.icon}`} />
                        <div className="benefit-list-item-text">
                          {item.benefit?.title}
                        </div>
                      </div>
                    </Col>
                  )) ||
                  null,
              )}
            </Row>
          </div>
        )}
      </Container>
    </section>
  );
};

export default BenefitsList;
