import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
  backToBlockOverview: {
    id: 'Blog.backToBlockOverview',
    defaultMessage: 'Back to blog overview',
  },
  seeHow: {
    id: 'Blog.seeHow',
    defaultMessage: 'see how',
  },
  writtenBy: {
    id: 'Blog.WrittenBy',
    defaultMessage: 'by',
  },
  writtenOn: {
    id: 'Blog.WrittenOn',
    defaultMessage: 'Written on',
  },
});

export default MESSAGES;
